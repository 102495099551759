import React from "react";
import getShift, {selectShiftOffset} from "../../../functions/getShift";
import {getExtraBorderStyles} from "./cel/Cel";
import {useSelector} from "react-redux";
import {selectCountersState} from "../../../reducers/selectors";
import {format} from "../../../../functions/date";

export default function CounterCel({date}){

    const state = useSelector(state => state);
    const counters = useSelector(selectCountersState);
    const shiftOffset = useSelector(selectShiftOffset);

    let counter = "";
    if(format(date) in counters){
        counter = counters[format(date)].total;
    }

    let counterStyle = '';
    if(counter === 10){
        counterStyle = " day-minimum-presence";
    }
    if(counter < 10 && counter > 0){
        counterStyle = "day-low-presence";
    }

    return(
      <div className="group">
          <div className="cel whitespace-small"></div>
          {
          getShift(date, shiftOffset) === "W"
              ? <div className="cel whitespace"></div>
              : <div className={"cel day-counter " + counterStyle + getExtraBorderStyles(date, state)}>{counter}</div>
          }
      </div>
    );
}