import React, {useState} from "react";
import {formatHumanShort} from "../../../../functions/date";
import {getGroup, getTeam} from "../../../../functions/structure";
import StateFields from "./StateFields";
import {deleteState} from "../../../../main/actions/stateActions";
import {useDispatch, useSelector} from "react-redux";
import {selectMembersListState} from "../../../reducers/selectors";
import {selectStructureState} from "../../../../redux/reducers/selectors";
import {fetchCareers} from "../../../actions/careersActions";

export default function CareerRow({state, current}){

    const listState = useSelector(selectMembersListState);
    const structure = useSelector(selectStructureState);
    const active = state.id === current.id ? "active " : "";
    const dispatch = useDispatch();
    const [deleted, setDeleted] = useState(false);

    const refetch = () => {
        dispatch(fetchCareers(listState.team_id));
    };

    const handleDelete = () => {

        setDeleted(state.id);
        dispatch(deleteState(state.id, refetch));
    }

    if(parseInt(state.enabled) === 0){
        return (
            <div className={"career-row " + active}>
                <div className="name">
                    {formatHumanShort(new Date(state.start_date))}
                </div>
                <div className="group">
                    inactief
                </div>
                <div className="group"></div>
                <div className="group"></div>
                <div className="group"></div>
                <div className="group"></div>
                <div className="group"></div>
                <div className="group"></div>
                <div className="group"></div>

                {state.id !== deleted
                    ? (<div className="actions icons show-on-hover children-right">
                            <i className={"material-icons"} onClick={handleDelete}>delete</i>
                        </div>
                    )
                    : (
                        <div className="actions icons children-right">
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    )
                }
            </div>
            );
    }

    return (

        <div className={"career-row " + active}>
            <div className="name">
                {formatHumanShort(new Date(state.start_date))}
            </div>
            <div className="team">
                {getTeam(structure, getGroup(structure, state.group_id).team_id).name}
            </div>
            <div className="group">
                {state.group_id}
            </div>
            <StateFields state={state} />
            {state.id !== deleted
                ? (<div className="actions icons show-on-hover children-right">
                        <i className={"material-icons"} onClick={handleDelete}>delete</i>
                    </div>
                )
                : (
                    <div className="actions icons children-right">
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
        </div>

    );

}