import React, {useEffect, useState} from "react";
import {Link, useParams} from "@reach/router";
import {getMemberSelector} from "../../../functions/member";
import {useDispatch, useSelector} from "react-redux";
import {isAllowed} from "../../../functions/authorization/authorization";
import './profile.css';
import getUrl from "../../functions/getUrl";
import {selectAppState} from "../../reducers/selectors";
import {updateMember} from "../../actions/memberActions";

export default function Profile(){

    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const appState = useSelector(selectAppState);
    const params = useParams();
    const member = useSelector(getMemberSelector(params.memberId));

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [allowListing, setAllowListing] = useState();

    useEffect(() => {
            setName(member.name);
            setEmail(member.email);
            setPhone(member.phone);
            setAllowListing(member.allow_listing);
    }, [member, setName, setEmail, setPhone]);

    const [edit, setEdit] = useState(false);

    const handleSubmit = () => {
        const data = {
            member_id: member.member_id,
            name,
            email,
            phone,
            allow_listing:allowListing,
        }
        dispatch(updateMember(member.member_id, data));
        setEdit(false);
    };

    return (
        <>
            <main>
                <article id="profile" className="page">
                    <section>
                        <Link to={getUrl(appState, {})}>
                            <div className="col4">
                                <i className="material-icons custom">keyboard_arrow_left</i>
                                <span>terug</span>
                            </div>
                        </Link>
                        <div className="col4 text-center"><h4>{member.name}</h4></div>
                        {isAllowed(Object.assign({}, state, {edit_member_id: parseInt(params.memberId)}), "edit-profile") &&
                            <div className="col4 text-right">
                                {!edit &&
                                <button className="btn btn-success" onClick={() => setEdit(true)}>
                                    <i className="material-icons custom">edit</i>
                                    <span>edit</span>
                                </button>
                                }
                                {edit &&
                                <>
                                    <button className="btn btn-success" onClick={handleSubmit}>
                                        <i className="material-icons custom">done</i>
                                        <span>save</span>
                                    </button>
                                    <button to={"/dashboard/accounts"} className="btn btn-secondary" onClick={() => setEdit(false)}>
                                    <i className="material-icons custom">clear</i>
                                    <span>cancel</span>
                                    </button>
                                </>
                                }
                            </div>
                        }
                    </section>
                    <section id="profile">
                        <div className="row">
                            <div className="col6">name</div>
                            {!edit &&
                            <div className="col6">{name}</div>
                            }
                            {edit &&
                            <input className="form-control col6" value={name} onChange={e => setName(e.target.value)} />
                            }
                        </div>
                        <div className="row">
                            <div className="col6">email</div>
                            {!edit &&
                            <div className="col6">{email}</div>
                            }
                            {edit &&
                            <input className="form-control col6" value={email} onChange={e => setEmail(e.target.value)} placeholder="example@mail.com"/>
                            }
                        </div>
                        <div className="row">
                            <div className="col6">telefoon</div>
                            {!edit &&
                            <div className="col6">{phone}</div>
                            }
                            {edit &&
                            <input className="form-control col6" value={phone} onChange={e => setPhone(e.target.value)} placeholder="04 12 34 56 78"/>
                            }
                        </div>
                        <div className="row">
                            <div className="col6">oplijsten toestaan</div>
                            {!edit &&
                            <div className="col6">{allowListing ? "ja" : "nee"}</div>
                            }
                            {edit &&
                            <div className="col6 toggle-wrapper">
                                <input className={"toggle"} type="checkbox" id={"allowListing"} name="allowListing" onChange={e => setAllowListing(!allowListing)} checked={allowListing}/>
                                <div className={"toggle"}>
                                    <label htmlFor={"allowListing"}></label>
                                </div>
                            </div>
                            }
                        </div>
                    </section>
                </article>
            </main>
        </>
    );
}