import React, {useState, useEffect} from "react";
import {Link, useLocation} from "@reach/router";

export default function NavLink({to, icon, name})
{

    const [active, setActive] = useState(false);

    const match = useLocation();

    useEffect(()=>{
        if(match.pathname === `/dashboard/${to}`){
            setActive(true);
        }else{
            setActive(false);
        }
    }, [match, setActive, to]);

    return (
        <div className={active ? "active link-wrapper" : "link-wrapper"}>
            <Link to={to}>
                <i className="material-icons">{icon}</i>
                <span className="link-name">{name}</span>
            </Link>
        </div>
    );

}