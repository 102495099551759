import {selectUserState} from "../../redux/reducers/selectors";
import {ADMIN_LEVEL} from "../../constants";

let actions = {};

export function isAllowed(state, action, params={}){

    const user = selectUserState(state);

    // admins have no restrictions
    if(user.level >= ADMIN_LEVEL){
        return true;
    }

    if(Object.keys(params).length === 0) {
        return (actions[action](user, state)) ? true : false;
    }else{
        return (actions[action](user, state, params)) ? true : false;
    }
}

export function register(action, verification){

    actions[action] = verification;
}

export function levelRequired(level){
    return function(user){
        if(level <= user.level){
            return true;
        }
        return false;
    }
}

export function isBetween(user, a, b){

    const lvl = user.level;

    return ((a <= lvl && lvl < b) || (b <= lvl && lvl < a))
}

/*
* Returns true if the given user can edit all rows in a team, false if user can only edit his own row
**/
export function canEditAll(user){
    return (user.level % 10 === 1);
}

