import React from 'react';
import {useSelector} from "react-redux";
import {selectAppState, selectRecordsState} from "../../../reducers/selectors";
import getStateDate from "../../../functions/getStateDate";
import Day from "./Day";

export default function Days () {

    const app = useSelector(selectAppState);
    let date = getStateDate(app);
    let days_in_month = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
    const records = useSelector(selectRecordsState);

    let days = new Array(days_in_month).fill(1);

    return (
        <>
        {days.map( (value, i) => {

                i+=1;
                let date_obj = new Date(date.getFullYear(), date.getMonth(), i);
                let datestring = app.year + "-" + app.month.pad(2) + "-" + i.pad(2);

                const input_data = (datestring in records) ? records[datestring] : [];

                return (<Day key={i}
                             date={date_obj}
                             data={input_data}
                        />
                );
        })}
        </>
    );
}