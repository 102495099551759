import {LOGIN, LOGOUT} from "../actions/userActions";

export default function userReducer(state, action){

    if(state === undefined){

        return {
            logged_in:false,
            level: 0,
            first_name: null,
            last_name: null,
            team_id: null,
            company_id: null,
        }
    }

    switch(action.type){

        case LOGIN:
            return Object.assign(
                {},
                state,
                {
                    logged_in: true,
                    first_name: action.first_name,
                    last_name: action.last_name,
                    level: parseInt(action.level),
                    company_id: parseInt(action.company_id),
                    member_id: parseInt(action.member_id),
                    team_id: parseInt(action.team_id)
                }
            );

        case LOGOUT:
            return Object.assign(
                {},
                state,
                {
                    logged_in:false,
                    first_name:null,
                    last_name:null,
                    level:null,
                    company_id:null,
                    member_id:null,
                    team_id:null,
                }
            );

        default:
            return state;

    }

}