import React from 'react';
import Buttons from "./Buttons";
import {setRecord} from "../../../actions/recordActions";
import {closeMenu, openMenu} from "../../../actions/appActions";
import {useDispatch, useSelector} from "react-redux";
import getShift, {selectShiftOffset} from "../../../functions/getShift";
import getMenuDate from "../../../functions/getMenuDate";
import {selectAppState} from "../../../reducers/selectors";
import {useCelData, useIsWeekendShift} from "../days/cel/Hooks";
import {HOLIDAY, HOLIDAY_MAIN_BUTTONS, HOLIDAY_SEC_BUTTONS} from "../../../../constants";
import {useIsCustomSchemeActive} from "../../../../functions/scheme";
import SchemeControl from "./SchemeControl";

export default function HolidayMenu(){

    const dispatch = useDispatch();
    const appState = useSelector(selectAppState);
    const celData = useCelData(getMenuDate(appState.menu_date), appState.menu_member.member_id);
    const menu_date = getMenuDate(appState.menu_date);
    const customScheme = useIsCustomSchemeActive(appState.menu_member.member_id, menu_date);
    const shiftOffset = useSelector(selectShiftOffset);
    const date = getMenuDate(appState.menu_date);
    const shiftW = useIsWeekendShift(date, shiftOffset);

    const main_buttons = HOLIDAY_MAIN_BUTTONS;
    const sec_buttons = HOLIDAY_SEC_BUTTONS;

    const onSetRecord = (value) =>{

        // save entered value to store
        dispatch(setRecord(HOLIDAY, menu_date, appState.menu_member, value));

        // keep moving one day to skip all W shifts
        let next_date = new Date(menu_date.getTime());
        next_date.setDate(next_date.getDate() + 1);
        while(getShift(next_date, shiftOffset) === "W" ) {
            next_date.setDate(next_date.getDate() + 1);
        }

        // if last day of month, clos menu
        if(next_date.getMonth() !== menu_date.getMonth()){
            dispatch(closeMenu());
        }else {
            dispatch(openMenu(appState.menu_member, next_date, appState.menu_corner));
        }
    }

    return(
        <>
        <div className="px-2">
            {!shiftW &&
                <>
                    <Buttons active={celData.holiday} mainButtons={main_buttons} secButtons={sec_buttons} setRecord={onSetRecord}/>
                    {customScheme ? <SchemeControl celData={celData} appState={appState}/> : null}
                </>
            }

            <textarea
                id="comment-field"
                rows="4"
                placeholder="opmerking"
                value={celData.comment ? celData.comment : ""}
                onChange={(e) => dispatch(setRecord("comment", menu_date, appState.menu_member, e.target.value, null, false))}
            >
            </textarea>
        </div>
        <div className="">
            <div
                className="inline-block w-1/2 lg:w-1/4 text-center py-2 lg-py-1 lg:mx-2 lg:mb-2 lg:rounded lg:float-right bg-coolgray-300 hover:bg-coolgray-400 font-bold text-gray-800"
                onClick={() => {
                    dispatch(setRecord("comment", menu_date, appState.menu_member, ""));/* VERY IMPORTANT TO USE "" INSTEAD OF NULL, if send null server will reject*/
                    dispatch(closeMenu());
                }}

            >verwijder</div>
            <div
                className="inline-block w-1/2 lg:w-1/4 text-center py-2 lg:py-1 lg:mx-2 lg:mb-2 lg:rounded lg:float-right bg-green-400 hover:bg-green-500 font-bold text-gray-800"
                onClick={() => {
                    dispatch(setRecord("comment", menu_date, appState.menu_member, document.getElementById("comment-field").value));
                    dispatch(closeMenu());
                }}
            >done</div>
        </div>
        </>
    )
}