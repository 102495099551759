import axios from "axios";
import {API_URL} from "../../constants";
import fetch from "cross-fetch";
import catchAuthError from "../../functions/catchAuthError";

export const CREATE_USER = "CREATE_USER";

export function createUser(firstName, lastName, email, username, password, level, memberId){
    return function (dispatch, getState) {

        const data = {
            first_name: firstName,
            last_name: lastName,
            email,
            username,
            password,
            level,
            member_id:memberId,
        }

        axios.defaults.withCredentials = true;
        axios.post(`${API_URL}/user/register`, data
        ).then((response) => {
            console.log("new user data posted");
            dispatch(fetchAccounts());
        }).catch((response) => {
            console.log(response);
        });
    }
}

export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";

export function fetchAccounts() {
    return function (dispatch) {

        return fetch(`${API_URL}/user`,
            {
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                }
            })
            .then(response => catchAuthError(response, dispatch))
            .then(
                response => response.json(),
                error => console.log("error eccored when fetching accounts", error)
            )
            .then(
                json => dispatch(receiveAccounts(json))
            );
    }
}

export const RECEIVE_ACCOUNTS = "RECEIVE_ACCOUNTS";

export function receiveAccounts(accounts){

    return {
        type: RECEIVE_ACCOUNTS,
        accounts,
    }

}