import React from 'react';
import Group from './Group';
import {useSelector} from 'react-redux'
import {selectAppState, selectMembersState} from "../../../reducers/selectors";
import {BONUS} from "../../../../constants";
import {getGroup} from "../../../../functions/structure";
import {selectStructureState} from "../../../../redux/reducers/selectors";
import {MONTH_NAMES} from "../../../../functions/date";

export default function MemberColumn(){

    const structure = useSelector(selectStructureState);
    const members = useSelector(selectMembersState);
    const appState = useSelector(selectAppState);

    let list = [];
    //loop groups
    for(const [id, group] of Object.entries(members)){

        if(parseInt(getGroup(structure, id).team_id) === parseInt(appState.team_id)) {
            list.push(
                <Group key={id} members={group}/>
            );
        }
    }

    let team_occupation = "";
    if(appState.mode !== BONUS){
        team_occupation = (
            <div className="group">
                <div className="cel whitespace-small"></div>
                <div className="cel user overflow-hidden">ploegbezetting</div>
            </div>
        );
    }

    return (
        <>
            <div className="member-column column bg-white sticky lg:relative left-0 z-30">
                <div className="cel head bg-white sticky lg:relative top-0 z-50">{MONTH_NAMES[appState.month-1]}</div>
                {list}
                {team_occupation}
            </div>
        </>
    );
}

