import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import List from "./List";
import {selectMembersListState} from "../../reducers/selectors";
import {selectUserState} from "../../../redux/reducers/selectors";
import {changeCompany, changeTeam, fetchMembersList} from "../../actions/membersListActions";
import {fetchCareers} from "../../actions/careersActions";
import ControlBar from "./list/ControlBar";
import "./members.css";

export default function Members () {

    const dispatch = useDispatch();

    const state = useSelector(selectMembersListState);
    const user = useSelector(selectUserState);
    const members = useSelector(state => selectMembersListState(state).members);

    const team_id = state.team_id;
    const date  = state.date;
    const company_id = state.company_id;

    useEffect(() => {
        if (state.company_id === null && state.team_id === null) {
            dispatch(changeCompany(user.company_id));
            dispatch(changeTeam(user.team_id));
        }
    }, [user, team_id, company_id, dispatch, state]);

    useEffect(() => {
        dispatch(fetchMembersList(team_id, date));
        dispatch(fetchCareers(team_id));
    }, [team_id, date, dispatch]);

    return (
        <>
            <ControlBar />
            <List members={members}/>
        </>
    );
}
