import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import getStateDate from "../../functions/getStateDate";
import {selectAppState} from "../../reducers/selectors";
import CounterColumn from "./countercolumn/CounterColumn";
import MemberColumn from "./membercolumn/MemberColumn";
import {HOLIDAY} from "../../../constants";
import "./calendar.css";
import {isAllowed} from "../../../functions/authorization/authorization";
import Menu from "./menu/Menu";
import ApproveBar from "./approvebar/ApproveBar";
import {changeDate, changeMode, changeTeam} from "../../actions/appActions";
import isLegitDate from "../../functions/isLegitDate";
import formatMonth from "../../functions/formatMonth";
import {useParams} from "@reach/router";
import {selectStructureState} from "../../../redux/reducers/selectors";
import {getTeams} from "../../../functions/structure";
import Days from "./days/Days";

export default function Calendar(){

    const state = useSelector(state => state);
    const app = useSelector(selectAppState);
    const structure = useSelector(selectStructureState);
    const teams = getTeams(structure, app.company_id);
    const params = useParams();
    const dispatch = useDispatch();

    const mode = useSelector(selectAppState).mode;
    let [params_valid, setParamsValid] = useState(true);
    let [message, setMessage] = useState("");

    // this function should run on every url change
    // it assumes the userState is correct, depends on company_id and team_id
    // function should only re-run on url params change, or when teams are loaded
    useEffect(() => {

        setParamsValid(true); // this reset is important
        // USER SHOULD BE SET CORRECTLY, WE NEED COMPANY ID

        // TEAM_ID ----------------------------------------------------------------------------------------------------
        if(Object.keys(teams).length === 0) {
            setParamsValid(false);
            setMessage("no teams loaded");

        }else{

            if(Object.keys(teams).includes(app.company_id.toString())){

                if (parseInt(params.team_id) !== app.team_id) {
                    dispatch(changeTeam(params.team_id)); // this also causes states, members, records
                }
            }else{
                setParamsValid(false);
                //setMessage("teams for this company not loaded");
            }
        }

        // DATE -------------------------------------------------------------------------------------------------------

        if (isLegitDate(params.date)) {
            if (params.date !== formatMonth(getStateDate(app))) {
                const datestring = params.date;
                const split = datestring.split("-");
                const year = split[0];
                const month = split[1];

                dispatch(changeDate(year, month));
            }
        } else {
            setParamsValid(false);
            setMessage("invalid date");
        }

        // MODE -------------------------------------------------------------------------------------------------------

        if (["holiday", "occupation", "bonus"].includes(params.mode)) {
            if (params.mode !== app.mode) {
                dispatch(changeMode(params.mode));
            }
        } else {
            setParamsValid(false);
            setMessage("invalid mode");
        }

    }, [params.team_id, params.date, params.mode, teams, app, dispatch]);

    if(params_valid) {
        return (
            <div className="flex justify-center">
                <div id="calendar-wrapper" className="flex-auto max-w-screen-3xl transition-all pt-12 px-2 pb-96">
                    {isAllowed(state, "approve-month") ? <ApproveBar/> : null}
                    <MemberColumn/>
                    <Days />
                    {mode === HOLIDAY ? <CounterColumn/> : null}
                </div>
                {isAllowed(state, "edit-cell") && app.menu_active === true &&
                    <Menu/>
                }
            </div>
        );
    }else{
        return (
            <span>{message}</span>
        )
    }
}
