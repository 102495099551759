import React, {useState} from "react";
import {Link} from "@reach/router";
import {onEnter} from "../../functions/onEnter";
import {postToApi} from "../../functions/postToApi";
import Error from "./Error";
import Done from "./Done";
import SubmitButton from "../SubmitButton";

export default function ForgotPassword ({username, setUsername}) {

    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = () => {
        postToApi(
            "forgot-password",
            {username},
            () => setDone(true),
            () => setError(true),
        );
    };

    return (

          <div className="py-2">
              <label className="sr-only" htmlFor="inlineFormInputGroup">Username</label>
              {!done &&
                  <div className="input-group mb-2">
                      <div className="input-group-prepend">
                          <div className="input-group-text">
                              <i className="material-icons">person</i>
                          </div>
                      </div>
                      <input type="text"
                             value={username}
                             className="form-control"
                             id="inlineFormInputGroup"
                             placeholder="Username"
                             onKeyUp={onEnter(handleSubmit)}
                             onChange={(e) => setUsername(e.target.value)}
                             autoFocus={true}/>
                  </div>

              }
              {error &&
                <Error>Er gaat iets mis, controleer uw username.</Error>
              }
              {done &&
                    <Done>Wachtwoord reset mail verzonden!</Done>
              }
              {!done &&
              <div className="text-center py-2">
                  <SubmitButton
                      onSubmit={handleSubmit}
                      disabled={(username === "")}
                  >
                      Verzend email
                  </SubmitButton>
                  <Link to="/login">
                      <div className="btn btn-secondary mb-2"
                           id="login-button"
                      >Cancel
                      </div>
                  </Link>
              </div>
              }
          </div>

    );
}