import React, {useState} from 'react';
import {Link} from "@reach/router";
import Dropdown from "./dropdown/Dropdown.js";
import getUrl from "../../functions/getUrl"
import {MONTH_NAMES} from "../../../functions/date";
import {useSelector} from "react-redux";
import {getTeamName} from "../../../functions/structure";
import {selectAppState} from "../../reducers/selectors";
import {selectStructureState} from "../../../redux/reducers/selectors";
import "./navigation.css";

export default function Navigation () {

    const appState = useSelector(selectAppState);
    const structure = useSelector(selectStructureState);

    const [monthsStyle, setMonthsStyle] = useState("hidden");

    /*
    * function to be called onClick for mobile touch users
    * the hoverable element is not visible for pc users, so no need to check screen sizes
    **/
    const toggleMonths = () => {
        document.getElementById("dropdown-wrapper").style.display = "none";
        setMonthsStyle(old => (old === "inline") ? "hidden" : "inline");
    };

    /*
    * function should be called onClick for mobile touch users to show dropdown
    * click function works also for pc users, main is hovering
    **/
    const toggleDropdown = () => {
        setMonthsStyle("hidden");
        const dropdown = document.getElementById("dropdown-wrapper");
        const display = dropdown.style.display;
        dropdown.style.display = (display === "flex") ? "none" : "flex";
    };

    /*
    * Function should be called to hide dropdown onMouseLeave nav area.
    * Screensize check is necessary because clicking on touch device results in triggering onMouseLeave and onClick,
    * resulting in doing an immediately undoing the action.
    **/
    const hideDropdown = () => {
        if(window.innerWidth > 1023){
            document.getElementById("dropdown-wrapper").style.display = "none";
        }
    }

    /*
    * Function should be called to hide dropdown onMouseEnter nav area.
    * Screensize check is necessary because clicking on touch device results in triggering onMouseEnter and onClick,
    * resulting in doing an immediately undoing the action.
    **/
    const showDropdown = () => {
        if(window.innerWidth > 1023) {
            document.getElementById("dropdown-wrapper").style.display = "flex";
        }
    }

    return (
        <>
            <nav id="main" className="w-screen fixed bg-white z-40 lg:top-0 left-0" onMouseLeave={hideDropdown}>
                <div className="w-full bg-green-400 flex justify-center h-38 lg:h-auto">
                    <div className="flex-auto max-w-screen-3xl">
                        <div className="dropdown-button py-2 w-1/3 lg:w-1/12 inline-block align-top" onClick={toggleDropdown} onMouseEnter={showDropdown}>
                            <div id="team-name-wrapper">
                                {getTeamName(structure, appState.company_id, appState.team_id)}
                            </div>
                            {/*
                        <div id="icon-wrapper">
                            <i className="material-icons">arrow_drop_down</i>
                        </div>
                        */}
                        </div>
                        <div className="w-1/3 lg:w-10/12 inline-block align-top" id="month-hover">
                            <div className="py-2 text-center hover:bg-green-700 lg:hidden" onClick={toggleMonths}>
                                {MONTH_NAMES[appState.month-1]}
                            </div>
                            {/*
                        visibility of the complete monthslist is always visible for lg devices, mobile devices are
                        determined by monthsStyle state, wich is "hidden" by default and toggled by clicking the element
                        above that makes it function like a dropdown
                        */}
                            <div className={"lg:w-full " + monthsStyle + " lg:inline"} id="months-list">
                                {
                                    MONTH_NAMES.map( (month, index) =>
                                        (
                                            <Link
                                                to={getUrl(appState, {month:index+1})}
                                                key={index}
                                                onClick={toggleMonths}
                                                className={
                                                    ((index+1 === appState.month) ? "bg-green-700 " : "")
                                                    + "py-2 w-full lg:w-1/12 inline-block text-center bg-green-400 hover:bg-green-700"
                                                }
                                            >
                                                { month }
                                            </Link>
                                        )
                                    )
                                }
                            </div>
                        </div>
                        <div className="py-2 w-1/3 lg:w-1/12 inline-block align-top text-center">
                            {appState.year}
                        </div>
                    </div>
                </div>
                <div id="dropdown-wrapper" className="w-full flex justify-center hidden border-b-2" onClick={toggleDropdown}>
                    <Dropdown/>
                </div>
            </nav>
        </>
    );
}
