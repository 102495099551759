import React, {useState} from 'react';

import Login from "./form/Login";
import ResetPassword from "./form/ResetPassword";
import ForgotPassword from "./form/ForgotPassword";
import Footer from "../../main/components/footer/footer";
import {navigate} from "@reach/router";

export default function Welcome ({loginType, noPath}){

      const [username, setUsername] = useState("");

      let form;
      switch (loginType){
            case "login":
                  form = <Login path="login" setUsername={setUsername} username={username} />;
                  break;

            case "forgot-password":
                  form = <ForgotPassword path="forgot-password" setUsername={setUsername} username={username} />;
                  break;

            case undefined:
                  form = <ResetPassword path="reset-password/:token/:email" setUsername={setUsername} username={username} />;
                  break;

            default:
                  form = <Login path="login" setUsername={setUsername} username={username} />;
                  break;
      }

      if(noPath){
            form = <Login path="login" setUsername={setUsername} username={username} />;
      }

      return (
            <>
                  <div className="lg:bg-coolgray-200 min-h-screen lg:flex lg:justify-center lg:items-center pb-10">
                        <div className="grid grid-cols-3 max-w-screen-lg lg:w-8/12 w-full h-2/3 lg:shadow-xl lg:rounded-lg overflow-hidden">

                              <div className="bg-welcome bg-cover hidden lg:inline"> </div>

                              <div className="col-span-3 lg:col-span-2 bg-white">
                                    <div className="mx-auto w-1/3 py-3 mt-4">
                                          <img src="/img/icons/factory-128.png" className="mx-auto lg:w-4/12 w-8/12" alt="background"/>
                                    </div>
                                    <div className="mx-auto text-center py-3 text-gray-600 text-4xl lg:text-5xl tracking-widest" style={{fontVariant:"small-caps"}}>
                                          lactam
                                    </div>
                                    <div className="mx-auto bg-white w-full lg:w-8/12 xl:w-7/12 px-2 py-3 sm:px-12 transition-all">
                                          {form}
                                    </div>
                                    <div className="mx-auto w-full lg:w-8/12 xl:w-7/12 py-2 pt-2 pb-5 lg:px-0 text-md lg:text-lg text-gray-600">
                                          <div className="text-center">
                                                <div>
                                                      {loginType === "login" || noPath
                                                          ? <span className="hover:underline hover:text-gray-900" onClick={() => navigate("/forgot-password")}>wachtwoord vergeten?</span>
                                                          : <span className="hover:underline hover:text-gray-900" onClick={() => navigate("/login")}>terug naar login</span>
                                                      }
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <Footer />
            </>
      );
}

