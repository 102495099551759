import React from 'react'
import Buttons from "./Buttons";
import {setRecord} from "../../../actions/recordActions";
import {closeMenu, openMenu} from "../../../actions/appActions";
import {useDispatch, useSelector} from "react-redux";
import getShift, {selectShiftOffset} from "../../../functions/getShift";
import getMenuDate from "../../../functions/getMenuDate";
import {selectAppState} from "../../../reducers/selectors";
import {OCCUPATION, OCCUPATIONS} from "../../../../constants";
import {isPresent, useCelData} from "../days/cel/Hooks";
import {getTomorrow} from "../../../../functions/date";

export default function OccupationMenu(){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const appState = useSelector(selectAppState);
    const menu_date = getMenuDate(appState.menu_date);
    const celData = useCelData(menu_date, appState.menu_member.member_id);
    const shiftOffset = useSelector(selectShiftOffset);


    const main_buttons = OCCUPATIONS;

    const onSetRecord = (value) => {

        // save entered value to store
        dispatch(setRecord(OCCUPATION, menu_date, appState.menu_member, value));

        let next = getTomorrow(menu_date);
        while(getShift(next, shiftOffset) === "W" || !isPresent(state, appState.menu_member.member_id, next)) {
            next.setDate(next.getDate() + 1);
        }

        if(next.getMonth() !== menu_date.getMonth()){
            dispatch(closeMenu());
        }else {
            dispatch(openMenu(appState.menu_member, next, appState.menu_corner));
        }

        if(next.getMonth() !== menu_date.getMonth()){
            dispatch(closeMenu());
        }else {
            dispatch(openMenu(appState.menu_member, next, appState.menu_corner));
        }
    }

    return(
        <>
            <Buttons mainButtons={main_buttons.slice(0, 5)} secButtons={null} active={celData.occupation} setRecord={onSetRecord}/>
            <Buttons mainButtons={main_buttons.slice(5, 8)} secButtons={null} active={celData.occupation} setRecord={onSetRecord} showDelete={false}/>
            <Buttons mainButtons={main_buttons.slice(8, 14)} secButtons={null} active={celData.occupation} setRecord={onSetRecord} showDelete={false}/>
            <Buttons mainButtons={main_buttons.slice(14, 17)} secButtons={null} active={celData.occupation} setRecord={onSetRecord} showDelete={false}/>
        </>
    )
}