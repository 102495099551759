import {combineReducers} from "redux";
import membersListReducer from "./membersListReducer"
import careersReducer from "./careersReducer";
import accountsReducer from "./accountsReducer";

export const  rootReducer = combineReducers({
    membersList: membersListReducer,
    careers: careersReducer,
    accounts: accountsReducer,
    }
);
