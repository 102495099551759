import React from "react";
import {useSelector} from "react-redux";
import {
    selectAppState,
    selectMembersState,
    selectStatesStates,
} from "../../../reducers/selectors";
import {getGroup} from "../../../../functions/structure";
import {selectStructureState} from "../../../../redux/reducers/selectors";
import {isActiveInCurrentMonth, isArbeiderInCurrentMonth} from "../membercolumn/Group";
import {BONUS} from "../../../../constants";
import {getMemberState} from "../../../../functions/state";
import {selectYearCounters} from "./selector";

export default function CounterColumn(){

    const members = useSelector(selectMembersState);
    const states = useSelector(selectStatesStates)
    const appState = useSelector(selectAppState);
    const counters = useSelector(selectYearCounters);
    const structure = useSelector(selectStructureState);
    const team_id = appState.team_id;

    return (
        <div className="column counter-column">
            <div className="cel head sticky lg:relative top-0 z-20 bg-white"> </div>
            {
                Object.entries(members).map(([group_id, group]) => {
                    if(parseInt(getGroup(structure, parseInt(group_id)).team_id) !== team_id){
                        return null
                    }
                    return (
                        <div key={group_id}>
                            <div className={"cel whitespace-small"}> </div>
                            <div className={"group"} key={group_id}>
                                {Object.keys(group).map(memberId => {
                                    if(
                                        (isArbeiderInCurrentMonth(appState, states, memberId) || appState.mode !== BONUS)
                                        && isActiveInCurrentMonth(appState, states, memberId)
                                    ) {
                                        return (
                                            <div key={memberId}>
                                                <div className={"cel"}>
                                                    {counters[memberId] ?? getMemberState(new Date(appState.year, 0, 1), memberId, states).holiday}
                                                </div>
                                                {appState.mode === BONUS &&
                                                <div className="cel"> </div>
                                                }
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    )
                })
            }
            {appState.mode !== BONUS &&
                <>
                    <div className={"cel whitespace-small"}> </div>
                    <div className={"cel"}> </div>
                </>
            }
        </div>
    );
}