import React, {useEffect} from 'react';
import {Link} from "@reach/router";
import {useDispatch, useSelector} from "react-redux";
import {fetchAccounts} from "../../actions/accountActions";
import {selectAccountsState} from "../../reducers/selectors";
import {
    ADMIN_LEVEL,
    COMPANY_MANAGEMENT_LEVEL,
    GENERAL_MANAGEMENT_LEVEL, MEMBER_LEVEL,
    TEAM_MANAGEMENT_LEVEL
} from "../../../constants";
import "./accounts.css";
import {getTeamName} from "../../../functions/structure";
import {selectStructureState} from "../../../redux/reducers/selectors";

export default function Accounts(){



    const dispatch = useDispatch();
    const accounts = useSelector(state => selectAccountsState(state).list);
    const structure = useSelector(state => selectStructureState(state));

    useEffect(() => {
        dispatch(fetchAccounts());
    }, [dispatch]);

    return (
        <>
        <section className="actions-bar">
            <div className="col4">
            </div>
            <div className="col4"></div>
            <div className="col4 children-right">
                <Link className="btn btn-success" to="new">
                    <i className="material-icons custom">add</i>
                    <span>new</span>
                </Link>
            </div>
        </section>

        <div className="account-list">
            <h4>Admin </h4>
            <div className="group">
                {Object.values(accounts).filter((acc) => acc.level >= ADMIN_LEVEL).map(acc => wrapInAccount(acc, structure))}
            </div>
            <h4>General Management</h4>
            <div className="group">
                {Object.values(accounts).filter((acc) => GENERAL_MANAGEMENT_LEVEL <= acc.level && acc.level < ADMIN_LEVEL).map(acc => wrapInAccount(acc, structure))}
            </div>
            <h4>Company Management</h4>
            <div className="group">
                {Object.values(accounts).filter((acc) => COMPANY_MANAGEMENT_LEVEL <= acc.level && acc.level < GENERAL_MANAGEMENT_LEVEL).map(acc => wrapInAccount(acc, structure))}
            </div>
            <h4>Team Management</h4>
            <div className="group">
                {Object.values(accounts).filter((acc) => TEAM_MANAGEMENT_LEVEL <= acc.level && acc.level < COMPANY_MANAGEMENT_LEVEL).map(acc => wrapInAccount(acc, structure))}
            </div>
            <h4>Members</h4>
            <div className="group">
                {Object.values(accounts).filter((acc) => MEMBER_LEVEL <= acc.level && acc.level < TEAM_MANAGEMENT_LEVEL).map(acc => wrapInAccount(acc, structure))}
            </div>
            <h4>Co-accounts</h4>
            <div className="group">
                {Object.values(accounts).filter((acc) => acc.level < MEMBER_LEVEL).map(acc => wrapInAccount(acc, structure))}
            </div>
        </div>
        </>
    );
}

function wrapInAccount(account, structure){

    return (
        <div className="account" key={account.id}>
                <div className="col2">{account.first_name + " " + account.last_name}</div>
                <div className="col2">{account.username}</div>
                <div className="col2">{account.member_id}</div>
                <div className="col2">{getTeamName(structure, account.company_id, account.team_id)}</div>
                <div className="col2">{account.level}</div>

        </div>
    );
}