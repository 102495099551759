import React from "react";

export default function Error ({children}) {

    return (
        <div className="alert alert-danger">
            {children}
        </div>
    );

}