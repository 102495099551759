import {navigate} from "@reach/router";
import {receivedExpired} from "../main/actions/appActions";

/**
 * This function can serve as argument for a .then function in a promise chain of a fetch request. If the response
 * contains an 401 or 419 http status code (Unauthorized), it redirects to the login page and sets the correct error
 * message.
 *
 * @param response
 * @param dispatch
 */
export default function catchAuthError (response, dispatch) {
    // 401 Unauthorized, 419 Page Expired (unofficial Laravel code)
    if (response.status === 401 || response.status === 419) {
        console.log("try to redirect to login")
        if(window.location.href.split('/').reverse()[0] !== 'login'){
            dispatch(receivedExpired());
            navigate('/login');
        }else {
            console.log("already on login page, no redirect");
        }
    }

    return response;
}