import React from 'react';
import HeadCel from './HeadCel';
import CelGroup from './CelGroup';
import getShift from '../../../functions/getShift.js';
import {useSelector} from "react-redux";
import {selectAppState, selectMembersState} from "../../../reducers/selectors";
import CounterCel from "./CounterCel";
import {BONUS} from "../../../../constants";
import {selectStructureState} from "../../../../redux/reducers/selectors";
import {getGroup, getTeam} from "../../../../functions/structure";

export default function Day({date, data}){

    const members = useSelector(selectMembersState);
    const app = useSelector(selectAppState);
    const mode = app.mode;
    const team_id = app.team_id;
    const structure = useSelector(selectStructureState);
    const offset = getTeam(structure, team_id).shift_offset;

    let list = [];
    //loop groups
    Object.entries(members).map(([group_id, group]) => {
        if(parseInt(getGroup(structure, group_id).team_id) === parseInt(team_id)) {
            list.push(
                <CelGroup
                    key={group_id}
                    group_id={group_id}
                    members={group}
                    date={date}
                    data={data}
                />);
        }
        return null;
    });

    let style = "column " + getShift(date, offset).toLowerCase();

    let current_date = new Date();
    current_date.setHours(0);
    current_date.setMinutes(0);
    current_date.setSeconds(0);
    current_date.setMilliseconds(0);
    if(date.getTime() === current_date.getTime()){
        style += " active"
    }

    return(
            <div className={style}>
                <HeadCel date={date} />
                {list}
                {mode !== BONUS ? (<CounterCel date={date}/>) : null}
            </div>
    );
}
