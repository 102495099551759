import React from "react";
import {getExtraBorderStyles} from "./Cel";
import {useIsCelActive, useIsMemberEnabled, useIsWeekendShift} from "./Hooks";
import {openMenu} from "../../../../actions/appActions";
import {useDispatch, useSelector} from "react-redux";
import {BEDIENDE, IGNORE_SCHEME_TOKEN, MIN_BEDIENDES} from "../../../../../constants";
import {selectSchemesState} from "../../../../../redux/reducers/selectors";
import {getSchemeIndex} from "../../../../../functions/scheme";
import {selectShiftOffset} from "../../../../functions/getShift";
import {selectCountersState} from "../../../../reducers/selectors";
import {format} from "../../../../../functions/date";

export default function Holiday({member, date, memberState, celData, group_id}){

    const enabled = useIsMemberEnabled(memberState, group_id);
    const state = useSelector(state => state);
    const shiftOffset = useSelector(selectShiftOffset);
    const shiftW = useIsWeekendShift(date, shiftOffset);
    const extraStyles = getExtraBorderStyles(date, state);
    const dispatch = useDispatch();
    const celActive = useIsCelActive(date, member.member_id);
    const weekendStyle = shiftW ? "w-day " : "";
    const activeStyle = celActive ? "active" : "";

    // bediendes
    const counters = useSelector(selectCountersState);
    let bediendesPresent = true;
    if(format(date) in counters){
        if(group_id in counters[format(date)]){
            bediendesPresent = (counters[format(date)].bediendes >= MIN_BEDIENDES);
        }
    }
    const isBediende = parseInt(memberState.function) === BEDIENDE;

    const bediendesStyle = (isBediende && !bediendesPresent) ? "bediendes-absent " : "";
    const schemes = useSelector(selectSchemesState);

    let content = null;
    let comment;
    let ignore;

    if(isCommentSet(celData) && enabled === true){
        comment = <>
            <span>{ celData.comment }</span>
            <div className="commentmarker"></div>
        </>
    }

    if(!shiftW){
        content = celData.holiday;
        ignore = (content === IGNORE_SCHEME_TOKEN);
    }

    // load custom scheme if no holiday cell in database is empty
    if((celData.holiday === null || celData.holiday === "") && !shiftW && memberState.scheme_id > 1){
        content = schemes[memberState.scheme_id]["scheme"][getSchemeIndex(date, shiftOffset)];
    }

    const handleClick = (e) => {
        const left = e.target.getBoundingClientRect().left;
        const top = e.target.getBoundingClientRect().top;
        dispatch(openMenu(member, date, [left, top]))
    };

    return (
        <div className={"cel " + extraStyles + " " + weekendStyle + " " + activeStyle + bediendesStyle}
             onClick={enabled ? handleClick : null}
        >
            {ignore ? "" : content}
            {comment}
        </div>
    );
}

function isCommentSet(celData){

    if(Object.keys(celData).includes("comment")){
        const comment = celData.comment;
        if(comment !== "" && comment !== null){
            return true;
        }
    }
    return false;
}