
import React, {useState, useEffect} from 'react';
import {Link, navigate} from "@reach/router";
import {getCompanyOptions, getGroupOptions, getTeamOptions} from "../../../../functions/forms/options";
import {useDispatch, useSelector} from "react-redux";
import {selectStructureState} from "../../../../redux/reducers/selectors";
import {getCompanies, getTeams, getGroups} from "../../../../functions/structure";
import {createMember, fetchMembersList} from "../../../actions/membersListActions";
import {selectMembersListState} from "../../../reducers/selectors";

export default function New()
{

    const structure = useSelector(state => selectStructureState(state));
    const state = useSelector(state => selectMembersListState(state));
    const [name, setName] = useState();
    const [companyId, setCompanyId] = useState(getCompanies(structure)[0][0]);
    const [teamId, setTeamId] = useState(getTeams(structure, companyId)[0][0]);
    const [groupId, setGroupId] = useState(getGroups(structure, teamId)[0]);
    const dispatch = useDispatch();

    // update dafult states when companyId changes
    // this is necessary in case a user does only change teamId in the menu
    useEffect(() => {
        setTeamId(getTeams(structure, companyId)[0][0]);
    }, [companyId, structure]);

    useEffect(() => {
        setGroupId(getGroups(structure, teamId)[0]);
    }, [teamId, structure]);



    function handleSubmit(){
        dispatch(createMember(name, groupId));
        dispatch(fetchMembersList(teamId, state.date))
        navigate("/dashboard/members");
    }

    return (
        <>
            <section className="actions-bar">
                <div className="col4">
                    <Link to={"/dashboard/members/"}>
                        <i className="material-icons custom">arrow_left</i>
                        <span>terug naar members</span>
                    </Link>
                </div>
                <div className="col4"></div>
                <div className="col4 children-right">
                    <button className="btn btn-success" onClick={() => handleSubmit()}>
                        <i className="material-icons custom">done</i>
                        <span>save</span>
                    </button>
                    <Link to={"/dashboard/accounts"} className="btn btn-secondary">
                        <i className="material-icons custom">clear</i>
                        <span>cancel</span>
                    </Link>
                </div>
            </section>

            <form>
                <div className="form-row">
                    <div className="col-md-6 mb-3">
                        <label>Naam</label>
                        <input type="text" className="form-control" placeholder="Alain" onChange={e => setName(e.target.value)}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6 mb-3">
                        <label>Bedrijf</label>
                        <select className="form-control form-control-sm col-md-3" onChange={e => {setCompanyId(parseInt(e.target.value))}}>
                            {getCompanyOptions(structure)}
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-md-6 mb-3">
                        <label>Ploeg</label>
                        <select className="form-control form-control-sm col-md-3" onChange={e => setTeamId(parseInt(e.target.value))}>
                            {getTeamOptions(structure, companyId)}
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-md-6 mb-3">
                        <label>Groep</label>
                        <select className="form-control form-control-sm col-md-3" onChange={e => setGroupId(parseInt(e.target.value))}>
                            {getGroupOptions(structure, teamId)}
                        </select>
                    </div>
                </div>

            </form>
        </>
    );

}