import React from "react";
import getUrl from "../../../main/functions/getUrl";
import {useDispatch, useSelector} from "react-redux";
import {selectAppState} from "../../../main/reducers/selectors";
import NavLink from "./NavLink";
import {selectUserState} from "../../../redux/reducers/selectors";
import "./navigation.css";
import {isAllowed} from "../../../functions/authorization/authorization";
import {logout} from "../../../redux/actions/userActions";

export default function Navigation(){

    const state = useSelector(state => state);
    const appState = useSelector(selectAppState);
    const team_id = useSelector(selectUserState).team_id;
    const dispatch = useDispatch();

    return (
        <nav id="dashboard-nav">
            <NavLink to={getUrl(appState, {team_id:team_id})} name="back to calendar" icon="arrow_back"/>
            <NavLink to="general" name="general" icon="apps" />
            <NavLink to="members" name="members" icon="person" />
            <NavLink to="schemes" name="schemes" icon="today" />
            {/*<NavLink to="groups" name="groups" icon="people"/>*/}
            {isAllowed(state, "accounts") &&
            <NavLink to="accounts" name="accounts" icon="account_box"/>
            }
            <div className="link-wrapper" onClick={() => dispatch(logout())}>
                <button>
                    <i className="material-icons">exit_to_app</i>
                    <span className="link-name">logout</span>
                </button>
            </div>
        </nav>
    );
}