import {selectStatesStates} from "../main/reducers/selectors";
import {getMemberState} from "./state";
import {useSelector} from "react-redux";
import {selectSchemesState} from "../redux/reducers/selectors";
import {useMemberState} from "../main/components/calendar/days/cel/Hooks";
import {wrapInOption} from "./forms/options";
import {selectShiftOffset} from "../main/functions/getShift";

export function getSchemeIndex(date, offset){

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    const shift_offset = new Date(offset);
    shift_offset.setDate(shift_offset.getDate()-1); // dont now why this is necessary but if i remove it, the scheme is shifted one day

    const diffSecs = date.getTime() - shift_offset.getTime();

    const diff = Math.floor(diffSecs / (1000*60*60*24));

    return (diff % 28);
}

export function hasCustomScheme(state, member_id, date){

    const states = selectStatesStates(state);
    const memberState = getMemberState(date, member_id, states);

    // wait for states
    if(memberState === null){
        return false;
    }
    if(memberState.scheme_id > 1){
        return true;
    }
    return false;
}

export function useHasCustomScheme(memberId, date){

    const states = useSelector(selectStatesStates);
    const state = getMemberState(date, memberId, states);
    if(state === null){return false}

    if(parseInt(state.scheme_id) > 1){
        return true;
    }
    return false;

}

// return whether a scheme is not "" on a certain index,
// this does not take holiday cel data in to account
export function useIsCustomSchemeActive(memberId, date){

    const hasCustomScheme = useHasCustomScheme(memberId, date);
    const schemes = useSelector(selectSchemesState);
    const memberState = useMemberState(date, memberId);
    const shiftOffset = useSelector(selectShiftOffset);

    if(!hasCustomScheme){
        return false;
    }

    const scheme = schemes[memberState.scheme_id]["scheme"];

    // a scheme is active if it is not ""
    return (scheme[getSchemeIndex(date, shiftOffset)] !== "");
}

export function isCustomSchemeActive(state, memberId, date){

    const hasScheme = hasCustomScheme(state, memberId, date);
    const schemes = selectSchemesState(state);
    const memberState = getMemberState(date, memberId, state.main.states);
    const shiftOffset = selectShiftOffset(state);

    if(!hasScheme){
        return false;
    }

    const scheme = schemes[memberState.scheme_id]["scheme"];

    // a scheme is active if it is not ""
    return (scheme[getSchemeIndex(date, shiftOffset)] !== "");
}

export function useGetScheme(memberId, date){

    const schemes = useSelector(selectSchemesState);
    const memberState = useMemberState(date, memberId);

    return schemes[memberState.scheme_id];
}

export function getSchemeAbbr(scheme){

    let i = 0;

    while(scheme[i] === ""){
        i++;
    }
    return scheme[i];
}

export function getSchemeOptions(schemes){

    return Object.entries(schemes).map(([scheme_id, scheme]) => [scheme_id, getSchemeAbbr(scheme.scheme)]).map(wrapInOption);
}


