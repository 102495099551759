export const LOGIN = "LOGIN";

export function login(data){
    return function(dispatch) {

        const user_data = {
          level:data.level,
          team_id: data.team_id,
          company_id: data.company_id,
          first_name: data.first_name,
          last_name: data.last_name,
            member_id: data.member_id,
        };


        const dispatch_obj = Object.assign({}, {type:LOGIN}, user_data);

        dispatch(dispatch_obj);
    }
}

export const LOGOUT = "LOGOUT";

export function logout(){
    return {
        type: LOGOUT
    }
}