import React from 'react';
import NavList from './NavList'
import {useSelector} from 'react-redux'
import ProfileSection from "../profile/ProfileSection";
import {selectAppState} from "../../../reducers/selectors";
import {isAllowed} from "../../../../functions/authorization/authorization";
import {ADMIN_LEVEL} from "../../../../constants";
import {selectStructureState, selectUserState} from "../../../../redux/reducers/selectors";
import {Link} from "@reach/router";
import {getTeams} from "../../../../functions/structure";


export default function Dropdown() {

    const state = useSelector(state => state);
    const app = useSelector(selectAppState);
    const structure = useSelector(selectStructureState);
    let teams = getTeams(structure, app.company_id);
    teams = teams.map(([id, name]) => [name, id]);
    const user = useSelector(selectUserState);


    // modes
    const modes_all = [["verlof", "holiday"], ["bezetting", "occupation"], ["toeslag", "bonus"]/*, ["weekends", "weekends"], ["zomer", "summer"]*/]

    const modes = modes_all.filter(([nl, en]) => {
        return isAllowed(state, "view-calendar", {mode:en});
    });

    // years
    let years = []
    const current_year = new Date().getFullYear()
    for(let i=-1; i< 4; i++){
        years.push([current_year + i, current_year +i])
    }

    //removed actions because the action is already dispatched after te route changed by clicking te Link
    // on render of de app or calendar component, app-actions were dispatched based on the requested url
    // this made redux update state twice, in future you should implement redux-router connection to fix this more properly, for now this approach works fine
    return (
        <section id="dropdown" className="w-full h-screen max-w-screen-3xl flex-auto lg:h-auto overflow-y-scroll block lg:flex">
            <NavList type="team_id" title="Ploeg" items={teams} />
            <NavList type="mode" title="Kalender" items={modes} />
            <NavList type="year" title="Jaar" items={years} />
            {user.level >= ADMIN_LEVEL &&
            <div className="nav-list sub-section">
                <div className="title text-center mx-1">extra</div>
                <div className="li-wrapper">
                   <Link to={app.team_id + "/list"}>team contact info</Link>
                </div>
            </div>
            }
            <ProfileSection/>
        </section>
    );

}