import {canEditAll, isBetween, levelRequired, register} from "./authorization";
import {
    ADMIN_LEVEL, BONUS,
    COMPANY_MANAGEMENT_LEVEL,
    GENERAL_MANAGEMENT_LEVEL, HOLIDAY,
    MANAGEMENT_LEVEL,
    MEMBER_LEVEL, OCCUPATION, TEAM_MANAGEMENT_LEVEL
} from "../../constants";
import {selectAppState} from "../../main/reducers/selectors";

export default function registerActions() {

    register("dashboard", levelRequired(MANAGEMENT_LEVEL));
    register("approve-holiday", levelRequired(MANAGEMENT_LEVEL));

    // members  can view all holiday and occupation calendars in their company
    //          can only see bonus in own team
    // companay/general management can see all calendars
    // mode params must be supplied
    register("view-calendar", (user, state, params) => {

        const ownTeam = parseInt(user.team_id) === parseInt(selectAppState(state).team_id);
        const ownCompany = parseInt(user.company_id) === parseInt(selectAppState(state).company_id);

        // mode not given, false
        if(!params.hasOwnProperty("mode")){
            return false;
        }

        // if invalid mode, false
        if(![HOLIDAY, OCCUPATION, BONUS].includes(params.mode)){
            return false;
        }

        // company management, can see all in own company
        if(isBetween(user, COMPANY_MANAGEMENT_LEVEL, GENERAL_MANAGEMENT_LEVEL) && ownCompany){
            return true;
        }

        // general management can see all
        if(isBetween(user, GENERAL_MANAGEMENT_LEVEL, ADMIN_LEVEL)){
            return true;
        }

        // all other levels must be members

        // if holiday or occupation, true
        if(([HOLIDAY, OCCUPATION].includes(params.mode) && ownCompany)){
            return true;
        }

        // bonus and own team, true
        if(params.mode === BONUS && ownTeam){
            return true;
        }

        // none of above, false
        return false;
    });

    register("edit-cell", (user, state) => {
        // all members can edit their own row
        // members and team managers can edit other rows in their own team if the account is level x1, this is current default
        // company- and general managers cannot edit cells


        // only members can edit
        if(!isBetween(user, MEMBER_LEVEL, COMPANY_MANAGEMENT_LEVEL)){
            return false
        }

        if(user.level % 10 === 0){
            if(parseInt(user.member_id) === parseInt(selectAppState(state).menu_member.member_id)){
                return true;
            }
            return false;
        }

        if(user.level % 10 === 1){
            if(parseInt(user.team_id) === selectAppState(state).team_id){
                return true;
            }
        }
        return false;
    });

    register("memberlist-companies", levelRequired(GENERAL_MANAGEMENT_LEVEL));
    register("memberlist-teams", levelRequired(COMPANY_MANAGEMENT_LEVEL));
    register("accounts", levelRequired(ADMIN_LEVEL));

    register("approve-month", (user, state) => {
        return (parseInt(user.team_id) === selectAppState(state).team_id && user.level >= TEAM_MANAGEMENT_LEVEL && user.level < COMPANY_MANAGEMENT_LEVEL);

    });

    register("edit-profile", (user, state) =>{
        // only team members are allowed to edit profiles
        if(isBetween(user, MEMBER_LEVEL, COMPANY_MANAGEMENT_LEVEL)){
            if(canEditAll(user) ||  state.edit_member_id === user.member_id){
                return true;
            }
        }
        return false;
    });

    // members can see profile info from all members in their own team
    // company and general management can see all info
    register("see-profile", (user, state) => {
        if(user.level >= COMPANY_MANAGEMENT_LEVEL || parseInt(user.team_id) === selectAppState(state).team_id){
            return true;
        }
        return false;
    });


}