import {RECEIVE_ACCOUNTS} from "../actions/accountActions";

export default function membersListReducer(state={list:{}}, action){

    switch(action.type){

        case RECEIVE_ACCOUNTS:
            return Object.assign({}, state, {list: action.accounts});

        default:
            return state;
    }
}
