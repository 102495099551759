
export function getCompanies (structure) {

    return Object.values(structure).map(company => [company.id, company.name]);
}

export function getTeams (structure, company_id) {
    if(!Object.keys(structure).includes(company_id.toString())){
        return [];
    }

    return Object.values(structure[company_id].teams).map(team => [team.id, team.name]);
}

export function getGroups (structure, team_id){

    return Object.values(structure).reduce((result, company) => {

        const groups = Object.values(company.teams).reduce((result, team) => {


            if(parseInt(team.id) === parseInt(team_id)){
                return Object.values(team.groups).map(group => group.id);
            }
            return result;
        }, null);

        if(groups !== null){
            return groups;
        }
        return result;

    }, null);

}

export function getGroup (structure, group_id) {

    return Object.values(structure).reduce((result, company) => {

        const groups = Object.values(company.teams).reduce((result, team) => {

                const group =  Object.values(team.groups).reduce((result, group) => {
                    if(parseInt(group.id) === parseInt(group_id)){
                        return group;
                    }
                    return result;
                }, null);
                if(group !== null){
                    return group;
                }
                return result;

        }, null);

        if(groups !== null){
            return groups;
        }
        return result;

    }, null);

}

export function getCompany(company_id){

}

export function getTeam(structure, team_id){

    return Object.values(structure).reduce((result, company) => {

        const team = Object.values(company.teams).reduce((result, team) => {

            if(parseInt(team.id) === parseInt(team_id)){
                return team
            }
            return result;

        }, null);

        if(team !== null){
            return team;
        }
        return result;

    }, null);
}

export function getTeamName(structure, companyId, teamId){

    return getTeams(structure, companyId).filter(team => team[0] === parseInt(teamId))[0][1];
}

export function getDefaultCompany(){
    return 1;
}

export function getDefaultTeam(){
    return 99;
}

export function getDefaultGroup(){
    return 991;
}

export function getDefaultMember(){
    return 8;
}