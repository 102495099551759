import React from 'react';
import getShift, {selectShiftOffset} from '../../../functions/getShift.js';
import {DAY_NAMES_SHORT} from "../../../../functions/date";
import {useSelector} from "react-redux";

export default function HeadCel({date}){

    const shiftOffset = useSelector(selectShiftOffset);
    let weekday = DAY_NAMES_SHORT[date.getDay()];

    return(
        <div className={"cel head " + weekday + " sticky lg:relative top-0 z-20 bg-white"}>
            <span className="day">{date.getDate()}</span>
            <span className="weekday">{weekday}</span>
            <span className="shift">{getShift(date, shiftOffset)}</span>
        </div>
    );
}