import React from 'react';
import {API_URL} from "../../../../constants";
import {logout} from "../../../../redux/actions/userActions";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "@reach/router";
import {isAllowed} from "../../../../functions/authorization/authorization";
import {getLevelName} from "../../../../functions/levels";

export default function ProfileSection () {

    const dispatch = useDispatch();

    const handleLogout = () => {
        console.log("ok")
        fetch(`${API_URL}/spa-logout`,
            {
                credentials: "include",
            }).then(() => {
                dispatch(logout());
        });
    };

    const user = useSelector(state => state.user);
    const state = useSelector(state => state);

    return (

        <section id="profile-section" className="col text-center pb-40 lg:pb-0">
            <div className="user-greeting col-12">Welkom {user.first_name} {user.last_name}</div>
            <div className="user-level col-12">{getLevelName(user.level)}</div>
            <div className="pb-3">{process.env.REACT_APP_STAGE}</div>
            <div className="col12">
                <button className="btn btn-secondary"  onClick={() =>handleLogout()}>
                    <i className="material-icons custom">exit_to_app</i>
                    <span>logout</span>
                </button>
                {isAllowed(state, "dashboard") &&
                    <Link to="/dashboard" className="btn btn-primary">
                        <i className="material-icons custom">build</i>
                        <span>dashboard</span>
                    </Link>
                }
            </div>
        </section>
    );
}
