import React from "react";

type props = {
    children: any,
    onSubmit: any,
    disabled: boolean,
}

export default function submitButton ({children, onSubmit, disabled}: props) {

    return (
        <div className={"btn btn-primary mb-2 " + (disabled ? "disabled" : "")}
             id="login-button"
             onClick={onSubmit}
        >
            {children}
        </div>
    );
}