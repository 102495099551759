import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectAppState} from "../../../reducers/selectors";
import getStateDate from "../../../functions/getStateDate";
import {MONTH_NAMES} from "../../../../functions/date";
import {approvePeriod} from "../../../actions/recordActions";
import {useSetOptionDefault} from "../../../../functions/forms/controls"

export default function ApproveBar(){

    const app = useSelector(state => selectAppState(state));
    const date = getStateDate(app);
    const days_in_month = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
    const monthName = MONTH_NAMES[date.getMonth()];
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    // fields
    const init_start = "01";
    const [start, setStart] = useState(init_start);
    useSetOptionDefault("approve-start-date", init_start);

    const init_end = days_in_month.pad(2);
    const [end, setEnd] = useState(init_end);
    useSetOptionDefault("approve-end-date", init_end);

    const handleClick = () => {
      console.log("clicked: from: " + start + " to " + end );

      if(parseInt(start) > parseInt(end)){
            setError("invalid period");
      }else{
          setError(null);
          const start_obj = new Date(app.year, app.month-1, start);
          const end_obj = new Date(app.year, app.month-1, end);
          dispatch(approvePeriod(app.team_id, start_obj, end_obj))
      }

    };

    return (
        <section id="approve-bar" className="hidden lg:block bg-coolgray-200 p-2 border-black w-full my-3">
            <div className="inline-block font-bold w-1/5">
                verlof goedkeuren
            </div>

            <div className="inline-block">
                van
            </div>

            <div className="inline-block">
                    <select className="form-control" id="approve-start-date" onChange={e => setStart(e.target.value)}>
                        {[...Array(days_in_month).keys()].map(day => (<option key={day}>{(day+1).pad(2)}</option>))}
                    </select>
            </div>

            <div className="inline-block pr-5">
                {monthName}
            </div>

            <div className="inline-block">
                tot
            </div>

            <div className="inline-block">
                    <select className="form-control" id="approve-end-date" onChange={e => setEnd(e.target.value)}>
                        {[...Array(days_in_month).keys()].map(day => (<option key={day}>{(day+1).pad(2)}</option>))}
                    </select>
            </div>

            <div className="inline-block pr-5">
                {monthName}
            </div>

            <div className="inline-block">
                <button className="btn btn-success btn-sm"  onClick={handleClick}>
                    <i className="material-icons custom">done</i>
                    <span>goedkeuren</span>
                </button>
            </div>

            <div className="text-danger inline-block">
                {error}
            </div>

        </section>
    )
}