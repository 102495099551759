import React from 'react';
import ReactDOM from 'react-dom';

import "./css/column_structure.css";
import "./css/variables.css";
import "./css/setup.css";
import './tailwind.output.css';

import * as serviceWorker from './serviceWorker';
import IndexRouter from "./IndexRouter";
import StoreProvider from "./redux/store/StoreProvider";


export function Wrapper(){
     return (
         <StoreProvider>
            <IndexRouter/>
         </StoreProvider>
     );
}

ReactDOM.render(
    <Wrapper/>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
