import React, {useState} from "react";
import {Link, useParams} from "@reach/router";
import {onEnter} from "../../functions/onEnter";
import {postToApi} from "../../functions/postToApi";
import Error from "./Error";
import SubmitButton from "../SubmitButton";

export default function ResetPassword () {

    const {token, email} = useParams();

    const [password, setPassword] = useState("");
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = () => {

        postToApi(
            "reset-password",
            {
                token,
                email,
                password,
                password_confirmation: password,
            },
            () => {
                setDone(true);
                setError(false);
            },
            () => {
                setError(true);
                setDone(false);
            });

    };

    return (
        <>
            {!done &&

                <div className="pb-2">
                    <label className="sr-only" htmlFor="inlineFormInputGroup">Password</label>
                    <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <i className="material-icons">lock</i>
                            </div>
                        </div>
                        <input type="password"
                               className="form-control password"
                               id="inlineFormInputGroup"
                               placeholder="Nieuw wachtwoord"
                               onKeyUp={onEnter(handleSubmit)}
                               onChange={(e) => setPassword(e.target.value)}
                               autoFocus
                        />

                    </div>

                    {error &&
                    <Error>Er liep iets fout, probeer later opnieuw!</Error>
                    }
                    {!done &&
                    <div className="text-center pb-2">
                        <SubmitButton
                            onSubmit={handleSubmit}
                            disabled={password === ""}
                        >
                            Reset
                        </SubmitButton>
                    </div>
                    }
                </div>

            }
            {done &&
            <div className="col-auto text-lg">
                Wachtwoord ingesteld! Terug naar <Link style={{color:"green"}} to="/login">aanmelden</Link>
            </div>
            }

        </>

    );

}