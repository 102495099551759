import {API_URL, SYNC, SYNC_INTERVAL} from "../../constants";
import {selectAppState} from "../reducers/selectors";
import axios from "axios";
import {formatUrl} from "../../functions/date";
import {selectSchemesState, selectStructureState} from "../../redux/reducers/selectors";
import catchAuthError from "../../functions/catchAuthError";

export const SET_HOLIDAY = "SET_HOLIDAY";
export const SET_OCCUPATION = "SET_OCCUPATION";
export const SET_VUIL = "SET_VUIL";
export const SET_OOR = "SET_OOR"
export const SET_COMMENT = "SET_COMMENT";

export function setHoliday(date, member, holiday){
    return {
        type: SET_HOLIDAY,
        date,
        member,
        holiday
    }
}

export function setOccupation(date, member, occupation){
    return {
        type: SET_OCCUPATION,
        date,
        member,
        occupation
    }
}

export function setOor(date, member, oor){
    return {
        type: SET_OOR,
        date,
        member,
        oor,
    }
}

export function setVuil(date, member, vuil){
    return {
        type: SET_VUIL,
        date,
        member,
        vuil
    }
}

export function setComment(date, member, comment){
    return {
        type: SET_COMMENT,
        date,
        member,
        comment
    }
}

export function setRecord(type, date, member, value, push=true) {

    return function (dispatch) {

        switch (type) {

            case "holiday":
                dispatch(setHoliday(date, member, value));
                break;

            case "occupation":
                dispatch(setOccupation(date, member, value));
                break;

            case "oor":
                 dispatch(setOor(date, member, value));
                 break;

            case "vuil":
                dispatch(setVuil(date, member, value));
                break;

            case "comment":
                dispatch(setComment(date, member, value))
                break;

            default:
                break
        }
        const data = {
            member_id: member.member_id,
            date: date.getFullYear() + "-" + (date.getMonth()+1).pad(2) + "-" + date.getDate().pad(2),
            type,
            value
        };

        if(push) {
            axios.defaults.withCredentials = true;
            axios.post(`${API_URL}/record`, data
            ).then((response) => {
                console.log("posted");
            }).catch((response) => {
                console.log(response);
            });

        }
    }
}

export function deleteRecord(type, date, member){

    return function (dispatch) {

        switch (type) {

            case "holiday":
                dispatch(setHoliday(date, member, null));
                break;

            case "occupation":
                dispatch(setOccupation(date, member, null));
                break;

            case "oor":
                dispatch(setOor(date, member, null));
                break;

            case "vuil":
                dispatch(setVuil(date, member, null));
                break;

            case "comment":
                dispatch(setComment(date, member, null))
                break;

            default:
                break
        }
        const data = {
            member_id: member.member_id,
            date: date.getFullYear() + "-" + (date.getMonth()+1).pad(2) + "-" + date.getDate().pad(2),
            type,
        };


            axios.defaults.withCredentials = true;
            axios.delete(`${API_URL}/record`, data
            ).then((response) => {
                console.log("deleted");
            }).catch((response) => {
                console.log(response);
            });


    }
}

export const RECEIVE_TEAM_RECORDS = "RECEIVE_TEAM_RECORDS";

export function receiveTeamRecords(team_id, records){
    return function(dispatch, getState) {
        dispatch({
            type: RECEIVE_TEAM_RECORDS,
            team_id,
            records,
            structure: selectStructureState(getState()), // BAD IMPLEMENTATION, SHOULD LEARN MORE ABOUT REDUCER STRUCTURING FIRST
            schemes: selectSchemesState(getState()), // IDEM, POSSIBLE TO FIND AN ELEGANT WAY TO SHARE STATE BETWEEN REDUCERS?
        })
    }
}

let timeout;

export function fetchTeamRecords(team_id, year, month, auto=false)
{
    return function(dispatch, getState) {

        const app = selectAppState(getState());
        if(app.records_fetch_timout === false && SYNC){
            clearTimeout(timeout);
            timeout = setTimeout(() => {dispatch(fetchTeamRecords(team_id, year, month, true))}, SYNC_INTERVAL);
        }

        if(auto === true && selectAppState(getState()).menu_active){
            return;
        }

        //request team records
        // let start = new Date(year, month-1, 1);
        // let end = plusOneMonth(start);

        const start = new Date(year, 0, 1);
        const end = new Date(year, 11, 31);

        return fetch(`${API_URL}/record?team_id=${team_id}&start=${formatUrl(start)}&end=${formatUrl(end)}`,
            {
                credentials: "include",
                headers:{
                    "Accept": "application/json",
                }
            })
            .then(response => catchAuthError(response, dispatch))
            .then(
                response => {
                    if(response.status === 200) {
                        return response.json();
                    }else{
                        console.log("fetching records not 200");
                        return {};
                    }
                },
                error => console.log("error fetching team records", error)
            )
            .then(
                json => dispatch(receiveTeamRecords(team_id, json)),
                error => console.log("error fetching team records", error)

            ).catch(
                error => console.log("error fetching team records", error)
            );
    }
}

export function approvePeriod(teamId, start, end){

    return function(dispatch){

        return fetch(`${API_URL}/record/approve?team_id=${teamId}&start=${formatUrl(start)}&end=${formatUrl(end)}`,
            {
                credentials: "include",
                headers:{
                    "Accept": "application/json",
                }
            })
                .then(
                    response => {

                        if(response.status === 200){
                            dispatch(fetchTeamRecords(teamId, start.getFullYear(), end.getMonth()+1));
                        }
                    }
                )

    }

}

