import {selectMembersState} from "../main/reducers/selectors";

export function getMemberSelector(memberId){

    return (state) => {

        const groups = selectMembersState(state);
        let result = {
            name:"",
            email:"",
            phone:"",
            allow_listing:false,
        };

        Object.entries(groups).forEach(([groupId, group]) => {
            Object.entries(group).forEach(([currMemberId, member]) => {
                if(currMemberId === memberId){
                   result = member;
                }
            });
        });
        return result;
    }
}