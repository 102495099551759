import React from "react";
import "../profile/profile.css";
import {useSelector} from "react-redux";
import {selectMembersState} from "../../reducers/selectors";
import "../profile/profile.css";// for now just use the styling of profile component in wait for genreal table row system

export default function TeamList(){

    const members = useSelector(selectMembersState);

    let list = [];

    Object.values(members).forEach(group => {
        Object.values(group).forEach(member => {
            list.push(
                <div className="row" key={member.member_id}>
                    <div className="col4">{member.name}</div>
                    <div className="col4">{member.email}</div>
                    <div className="col4">{member.phone}</div>
                </div>
            );
        })
    })

    return (
        <>
            <main>

                <article className="page" id={"profile"}>
                    <section id="profile">
                        <div className="row" style={{background:"white"}}>
                            <div className="col4">naam</div>
                            <div className="col4">email</div>
                            <div className="col4">telefoon</div>
                        </div>
                    </section>
                    <section id="profile">

                        {list}
                    </section>

                </article>

            </main>

        </>
    );
}