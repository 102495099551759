import React, {useState, useEffect} from 'react';
import {Link, navigate} from "@reach/router";
import {getCompanyOptions, getTeamOptions, wrapInOption} from "../../../functions/forms/options";
import {useDispatch, useSelector} from "react-redux";
import {selectStructureState} from "../../../redux/reducers/selectors";
import {
    ADMIN_LEVEL,
    CO_ACCOUNT_LEVEL,
    COMPANY_MANAGEMENT_LEVEL,
    GENERAL_MANAGEMENT_LEVEL,
    MEMBER_LEVEL,
    TEAM_MANAGEMENT_LEVEL
} from "../../../constants";
import {getDefaultCompany, getDefaultMember, getDefaultTeam} from "../../../functions/structure";
import { useSetOptionDefault} from "../../../functions/forms/controls";
import {fetchMembersList} from "../../actions/membersListActions";
import {selectMembersListState} from "../../reducers/selectors";
import {createUser} from "../../actions/accountActions";

export default function New(){

    //deps
    const structure = useSelector(state => selectStructureState(state));
    let members = useSelector(state => selectMembersListState(state)).members;

    const dispatch = useDispatch();
    //component states
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [username, setUsername] = useState();
    const [membersList, setMembersList] = useState([]);

    const [password, setPassword] = useState();
    const [level, setLevel] = useState(0);
    const [company, setCompany] = useState(getDefaultCompany());
    useSetOptionDefault("company", getDefaultCompany());
    const [team, setTeam] = useState(getDefaultTeam);
    useSetOptionDefault("team", getDefaultTeam());
    const [member, setMember] = useState(getDefaultMember);

    useEffect(() => {
        //getting members
        const list = [];
        Object.values(members).map(group => {
            Object.values(group).map(member => list.push([parseInt(member.member_id), member.name]));
            return null;
        });
        setMembersList(list);
    }, [members]);

    useEffect(() => {
        dispatch(fetchMembersList(team, new Date()));
    }, [team, dispatch]);

    useEffect(() => {
        if(membersList.length > 0) {
            setMember(membersList[0][0]);
        }
    }, [team, membersList]);

    const handleSubmit = () => {
        dispatch(createUser(firstName, lastName, email, username, password, level, member));
        navigate('/dashboard/accounts')
    }

    return (
        <>
            {/* ACTION BAR ------------------------------------------------------------------------------------------*/}
        <section className="actions-bar">
            <div className="col4">
                <Link to={"/dashboard/accounts/"}>
                    <i className="material-icons custom">arrow_left</i>
                    <span>terug naar accounts</span>
                </Link>
            </div>
            <div className="col4"></div>
            <div className="col4 children-right">
                <button className="btn btn-success" onClick={() => handleSubmit()}>
                    <i className="material-icons custom">done</i>
                    <span>save</span>
                </button>
                <Link to={"/dashboard/accounts"} className="btn btn-secondary">
                    <i className="material-icons custom">clear</i>
                    <span>cancel</span>
                </Link>
            </div>
        </section>

            {/*CONTENT ----------------------------------------------------------------------------------------------*/}
        <form>
            <div className="col6">
                <h3 className="col9 text-center">Personal Information</h3>
                <div className="form-row">
                    <div className="col-md-9 mb-3">
                        <label>Voornaam</label>
                        <input type="text" className="form-control" placeholder="Alain" onChange={e => setFirstName(e.target.value)}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-9 mb-3">
                        <label>Achternaam</label>
                        <input type="text" className="form-control" placeholder="Vandamme" onChange={e => setLastName(e.target.value)}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-9 mb-3">
                        <label>e-mail</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">@</span>
                            </div>
                            <input type="email" className="form-control" id="email" placeholder="alain@hotmail.com" onChange={e => setEmail(e.target.value)} required />
                            <div className="invalid-feedback">
                                    Please enter valid email.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-9 mb-3">
                        <label>username</label>
                        <div className="input-group">
                            <input type="text" className="form-control" id="username" placeholder="alaindv12" onChange={e => setUsername(e.target.value)} required/>
                            <div className="invalid-feedback">
                                This username already exists.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-9 mb-3">
                        <label>password</label>
                        <div className="input-group">
                            <input type="password" className="form-control" id="password" onChange={e => setPassword(e.target.value)} required/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col6">
                <h3 className="col9 text-center">Authorization</h3>
                <div className="col-md-9 mb-3 form-row">
                    <label>level</label>
                    <select className="form-control" onChange={e => setLevel(e.target.value)}>
                        <option>-- kies --</option>
                        <option value={CO_ACCOUNT_LEVEL}>Co-account</option>
                        <option value={MEMBER_LEVEL+1}>Member</option>
                        <option value={TEAM_MANAGEMENT_LEVEL+1}>Team Management</option>
                        <option value={COMPANY_MANAGEMENT_LEVEL}>Company Management</option>
                        <option value={GENERAL_MANAGEMENT_LEVEL}>General Management</option>
                        <option value={ADMIN_LEVEL}>Admin</option>
                    </select>
                </div>

                {(MEMBER_LEVEL <= level && level < ADMIN_LEVEL) &&
                <div className="col-md-9 mb-3 form-row">
                    <label>can edit other team members?</label>
                    <select className="form-control" onChange={e => {
                        setLevel(parseInt(level.toString().substr(0, 1) + e.target.value));
                    }}>
                        <option value={1}>Ja</option>
                        <option value={0}>Nee</option>
                    </select>
                </div>
                }
                
                <h3 className="col9 text-center">Linked Member</h3>
                <div className="col-md-9 mb-3 form-row">
                    <label>Company</label>
                    <select id="company" className="form-control" onChange={e => setCompany(e.target.value)}>
                        {getCompanyOptions(structure)}
                    </select>
                </div>

                <div className="col-md-9 mb-3 form-row">
                    <label>Team</label>
                    <select id="team" className="form-control" onChange={e => {setTeam(e.target.value)}}>
                        {getTeamOptions(structure, company)}
                    </select>
                </div>

                <div className="col-md-9 mb-3 form-row">
                    <label>Member</label>
                    <select id="member" className="form-control" onChange={e => setMember(e.target.value)}>
                        {membersList.map(wrapInOption)}
                    </select>
                </div>
            </div>


        </form>
        </>
    );
}