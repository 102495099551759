
export function selectAppState(state){
    return state.main.app;
}

export function selectMembersState(state){
    return state.main.members;
}

export function selectRecordsState(state){
    return state.main.records;
}

export function selectStatesStates(state){
    return state.main.states;
}

export function selectYearCountersState(state){
    return state.main.yearCounters;
}

export function selectCountersState(state){
    return state.main.counters;
}