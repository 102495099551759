import React, {useEffect} from "react";
import NotFound from "./NotFound";
import {useSelector} from "react-redux";
import {navigate, Router} from "@reach/router"
import Profile from "./profile/Profile";
import {selectMembersState} from "../reducers/selectors";
import TeamList from "./teamlist/TeamList";
import {selectSchemesState, selectStructureState, selectUserState} from "../../redux/reducers/selectors";
import "./main.css";
import Navigation from "./navigation/Navigation";
import Calendar from "./calendar/Calendar";

export default function Main () {

    const members = useSelector(selectMembersState);
    const logged_in = useSelector(selectUserState).logged_in;
    const structure = useSelector(selectStructureState);
    const schemes = useSelector(selectSchemesState);

    // if not logged in, always display welcome screen -----------------------------------------------------------------
    useEffect(() => {
        if(logged_in !== true){
            navigate("/login");
        }
    }, [logged_in]);

    useEffect(() => {
        document.getElementById("body").className = "theme-light";
    }, []);


    if(isEmptyObj(structure) || isEmptyObj(schemes)){
        return <div>loading...</div>;
    }

    return (
        <div className="w-300 sm:w-200 lg:w-full pb-96 lg:pb-0">
            {logged_in === true &&
                <div>
                <Navigation/>
                    <Router>

                        <Calendar path=":team_id/:mode/:date"/>
                        {Object.keys(members).length !== 0 &&
                        <Profile path="profile/:memberId"/>
                        }
                        <TeamList path=":team_id/list"/>
                        <NotFound default/>
                    </Router>
                </div>
                    }
        </div>
    );

}

function isEmptyObj(obj) {
    return (JSON.stringify(obj) === JSON.stringify({}));
}