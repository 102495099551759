import React from "react";

export default function Done ({children}) {

    return (
        <div className="col-auto text-lg pb-4 w-9/12 mx-auto text-gray-700">
            {children}
        </div>
    );

}