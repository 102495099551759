import React from 'react';
import Buttons from "./Buttons";
import {closeMenu, openMenu} from "../../../actions/appActions";
import {useDispatch, useSelector} from "react-redux";
import getShift, {selectShiftOffset} from "../../../functions/getShift";
import getMenuDate from "../../../functions/getMenuDate";
import {selectAppState} from "../../../reducers/selectors";
import {isPresent, useCelData} from "../days/cel/Hooks";
import {setRecord} from "../../../actions/recordActions";
import {getTomorrow} from "../../../../functions/date";
import {OOR, VUIL} from "../../../../constants";

export default function BonusMenu(){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const appState = useSelector(selectAppState);
    const menu_date = getMenuDate(appState.menu_date);
    const celData = useCelData(menu_date, appState.menu_member.member_id);
    const shiftOffset = useSelector(selectShiftOffset);

    const main_buttons = [1, 2, 3, 4, 5, 6];

    const setVuil = (vuil) => dispatch(setRecord(VUIL, menu_date, appState.menu_member, vuil));

    const setOor = (oor) => {

        dispatch(setRecord(OOR, menu_date, appState.menu_member, oor));

        let next = getTomorrow(menu_date);
        while(getShift(next, shiftOffset) === "W" || !isPresent(state, appState.menu_member.member_id, next)) {
            next.setDate(next.getDate() + 1);
        }

        if(next.getMonth() !== menu_date.getMonth()){
            dispatch(closeMenu())
        }else {
            dispatch(openMenu(appState.menu_member, next, appState.menu_corner))
        }
    }

    return(
        <>
            <div className="bonus-title">vuil</div>
            <Buttons mainButtons={main_buttons} secButtons={null} active={parseInt(celData.vuil)} setRecord={setVuil}/>
            <div className="bonus-title">oren</div>
            <Buttons mainButtons={main_buttons} secButtons={null} active={parseInt(celData.oor)} setRecord={setOor}/>
        </>
    )
}