
// versioning
export const VERSION = "2.0";
export const VERSION_NAME = "nitrogen (beta)";

// api
export const API_URL = process.env.REACT_APP_API_URL;

// permissions
export const CO_ACCOUNT_LEVEL = 10;
export const MEMBER_LEVEL = 20;
export const MANAGEMENT_LEVEL = 30; // should be removed
export const TEAM_MANAGEMENT_LEVEL = 30;
export const COMPANY_MANAGEMENT_LEVEL = 40;
export const GENERAL_MANAGEMENT_LEVEL = 50;
export const ADMIN_LEVEL = 60;

export const LEVEL_NAMES = {
    10: "co-account",
    20: "member",
    30: "team management",
    40: "company management",
    50: "general management",
    60: "administrator"
};

// syncing records settings
export const SYNC = process.env.RECORDS_SYNC === 'true';
export const SYNC_INTERVAL = 5000;

// inactivity settings
export const ACTIVITY_CHECK_INTERVAL = 10*1000
export const INACTIVITY_LIMIT = 2*60*1000
export const LAST_ACTIVITY = "lastActivity";

// modes
export const HOLIDAY = "holiday";
export const OCCUPATION = "occupation";
export const BONUS = "bonus";
export const VUIL = "vuil";
export const OOR = "oor";

// education
export const EDUCATIONS = [
    "PL", "APL", // ploegleiding
    "SC", "RC", "PC", // cordinator
    "sb", "rb", "pb", // bord
    "su", "ru", "pu", // buiten
    "so", "ro", "po", // opleiding/overschot
    "-",
];

export const OCCUPATIONS = [
    "PL", "APL", // ploegleiding
    "SC", "RC", "PC", // cordinator
    "SVC", "RVC", "PVC", // vervanger
    "sb", "rb", "pb", // bord
    "su", "ru", "pu", // buiten
    "so", "ro", "po", // opleiding/overschot
];

export const HOLIDAY_MAIN_BUTTONS = ["x", "L", "Z",  "SW"]
export const HOLIDAY_SEC_BUTTONS = ["h1", "h2", "BAO", "VAP", "8", "VWB", "EOP", "HOV", "KV", "AZC"];

// functions
export const BEDIENDE = 1;
export const ARBEIDER = 2;
export const MIN_BEDIENDES = 2;

export const IGNORE_SCHEME_TOKEN = "-";

export const SHIFT_SCHEME = [
    'V', 'V', 'L', 'L', 'N', 'N', 'N', 'W', 'W',
    'V', 'V', 'L', 'L', 'L', 'N', 'N', 'W', 'W',
    'V', 'V', 'V', 'L', 'L', 'N', 'N', 'W', 'W', 'W'
];