import {useSelector} from "react-redux";
import {
    selectAppState,
    selectRecordsState,
    selectStatesStates
} from "../../../../reducers/selectors";
import formatDate from "../../../../functions/formatDate";
import getShift, {selectShiftOffset} from "../../../../functions/getShift";
import getMenuDate from "../../../../functions/getMenuDate";
import {getMemberState} from "../../../../../functions/state";
import {hasCustomScheme} from "../../../../../functions/scheme";
import {selectSchemesState} from "../../../../../redux/reducers/selectors";
import {HOLIDAY_MAIN_BUTTONS, HOLIDAY_SEC_BUTTONS, IGNORE_SCHEME_TOKEN} from "../../../../../constants";

export function useMemberState(date, memberId, customSelector=null) {

    let selector;
    if(customSelector === null){
        selector = selectStatesStates;
    }else{
        selector = customSelector;
    }

    const states = useSelector(state => selector(state));

    return getMemberState(date, memberId, states);
}

export function useCelData(date, memberId){

    const records = useSelector(state => selectRecordsState(state));
    return getCelData(records, date, memberId)
}

export function getCelData(records, date, memberId){
    // default cell, if no matches in state
    if(formatDate(date) in records) {

        if(memberId in records[formatDate(date)]) {

            return records[formatDate(date)][memberId];
        }
    }

    // return default cell
    return {
        "holiday": null,
        "occupation": null,
        "vuil": null,
        "oor": null,
        "comment": null
    };
}

export function useIsMemberEnabled(memberState, groupId){

    if(memberState === null){
        return null;
    }

    return !(parseInt(memberState.group_id) !== parseInt(groupId) ||
        parseInt(memberState.enabled) !== 1);

}

export function useIsWeekendShift(date, offSet){

    return getShift(date, offSet) === "W";

}

export function useIsCelActive(date, memberId){

    const app = useSelector(state =>selectAppState(state));

    if(date.getTime() === getMenuDate(app.menu_date).getTime() &&
        memberId === app.menu_member.member_id &&
        app.menu_active === true){
        return true;
    }
    return false;
}

// prefer to use this version if possible, it is written correctly according to the offical react docs
// when nog using offical hooks functions, you cannot be sure the state is matched in correct synchronical order
// read more on Hooks Rules in react docs
export function useIsPresent(celData_input, memberId, date){

    const state = useSelector(state => state);
    const shiftOffset = useSelector(selectShiftOffset);
    const schemes = useSelector(selectSchemesState);
    const memberState = useMemberState(date, memberId);
    const celData = useCelData(date, memberId);

    if(hasCustomScheme(state, memberId, date)){
        const scheme = schemes[memberState.scheme_id]["scheme"];

        // a member is absent when his scheme is active and their is no ignore_token set
        if(getShift(date, shiftOffset, scheme) !== "" && ![IGNORE_SCHEME_TOKEN].includes(celData.holiday)){
            return false;
        }
    }

    const holiday_options = HOLIDAY_MAIN_BUTTONS.concat(HOLIDAY_SEC_BUTTONS);
    if(holiday_options.includes(celData.holiday)){
        return false;
    }
    return true;
}

// this version can be used in places where calling hooks is not allowed eg. callbacks
export function isPresent(state, memberId, date){

    const shiftOffset = selectShiftOffset(state);
    const schemes = selectSchemesState(state);
    const memberState = getMemberState(date, memberId, selectStatesStates(state));
    const celData = getCelData(selectRecordsState(state), date, memberId);

    if(hasCustomScheme(state, memberId, date)){
        const scheme = schemes[memberState.scheme_id]["scheme"];

        if(getShift(date, shiftOffset, scheme) !== "" && ![IGNORE_SCHEME_TOKEN, "", null].includes(celData.holiday)){
            return false;
        }
    }

    const holiday_options = HOLIDAY_MAIN_BUTTONS.concat(HOLIDAY_SEC_BUTTONS);
    if(holiday_options.includes(celData.holiday)){
        return false;
    }
    return true;

}