import pad from "../../functions/pad";


function getUrl(appState, values){

    let url = {}
    url.team_id = appState.team_id
    url.mode = appState.mode
    url.year = appState.year
    url.month = appState.month

    Object.assign(url, values)

    return "/" + url.team_id + "/" + url.mode + "/" + url.year + "-" + pad(url.month, 2) + "/"

}

export default getUrl