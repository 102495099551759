import React, {useState} from "react";
import axios from "axios";
import {API_URL} from "../../../constants";
import {login} from "../../../redux/actions/userActions";
import {
    changeCompany as calendarChangeCompany,
    changeTeam as calendarChangeTeam, resetExpired
} from "../../../main/actions/appActions";
import {
    changeCompany as memberlistChangeCompany,
    changeTeam as memberlistChangeTeam
} from "../../../dashboard/actions/membersListActions";
import {navigate} from "@reach/router";
import getUrl from "../../../main/functions/getUrl";
import {useDispatch, useSelector} from "react-redux";
import {selectAppState} from "../../../main/reducers/selectors";
import Error from "./Error";
import {onEnter} from "../../functions/onEnter";

export default function  Login ({username, setUsername}) {

    const appState = useSelector(selectAppState);
    const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const handleLogin = () => {

        if(!username || !password){
            return;
        }

        axios.defaults.withCredentials = true;
        axios.get(`${API_URL}/airlock/csrf-cookie`).then(response => {
            axios.post(`${API_URL}/spa-login`, {
                username: username,
                password: password,
            }).then(response => {
                if(response.status === 200){
                    dispatch(login(response.data));
                    // same dispatches as in syncUserApp
                    dispatch(calendarChangeCompany(response.data.company_id));
                    dispatch(calendarChangeTeam(response.data.team_id));
                    dispatch(memberlistChangeCompany(response.data.company_id));
                    dispatch(memberlistChangeTeam(response.data.team_id));
                    dispatch(resetExpired());
                    navigate(getUrl(appState, {team_id:response.data.team_id}));
                }
            }).catch(response => {
                setError(true);
            });

        }).catch(response => {
            setError(true);
        });
    };

    return (
        <>
            <div className=" py-2">
                <label className="sr-only" htmlFor="inlineFormInputGroup">Username</label>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <i className="material-icons">person</i>
                        </div>
                    </div>
                    <input type="text"
                           value={username}
                           className="form-control"
                           id="inlineFormInputGroup"
                           placeholder="Username"
                           onKeyUp={onEnter(handleLogin)}
                           onChange={(e) => setUsername(e.target.value)}
                           autoFocus={true}

                    />
                </div>
            </div>

            <div className="py-2">
                <label className="sr-only" htmlFor="inlineFormInputGroup">Password</label>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <i className="material-icons">lock</i>
                        </div>
                    </div>
                    <input type="password"
                           className="form-control password"
                           id="inlineFormInputGroup"
                           placeholder="Password"
                           onKeyUp={onEnter(handleLogin)}
                           onChange={(e) => setPassword(e.target.value)}

                    />
                </div>
            </div>

            {error &&
                <Error>
                    Uw gebruikersnaam en wachtwoord komen niet overeen. Probeer nog eens.
                </Error>
            }
            {appState.expired &&
                <Error>
                    Uw sessie is verlopen. Gelieve opnieuw in te loggen.
                </Error>
            }
            <div className="col-auto text-center py-2">
                <div className={"btn btn-primary mb-2 " + (username && password ? "" : "disabled")}
                     id="login-button"
                     onClick={handleLogin}
                >Aanmelden</div>
            </div>

        </>
    );
}