import React from 'react'
import Cel from './cel/Cel'
import {useSelector} from "react-redux";
import {selectAppState, selectCountersState, selectStatesStates} from "../../../reducers/selectors";
import {HOLIDAY} from "../../../../constants";
import {format} from "../../../../functions/date";
import {isActiveInCurrentMonth} from "../membercolumn/Group";

export default function CelGroup({members, date, group_id}){

    const mode = useSelector(selectAppState).mode;
    const counters = useSelector(selectCountersState);
    const appState = useSelector(selectAppState);
    const memberStates = useSelector(selectStatesStates);
    let isPresent = true;

    if(format(date) in counters){

        if(group_id in counters[format(date)]) {
            isPresent = counters[format(date)][group_id].present > 1;
        }
    }

    const absentStyle = !isPresent && mode === HOLIDAY ? "absent-group": null;
    return (
        <div className={"group " + absentStyle}>
            <div className="cel whitespace-small"></div>
            {Object.values(members).map(member => {
                if(isActiveInCurrentMonth(appState, memberStates, member.member_id)) {
                    return (<Cel key={member.member_id}
                                 group_id={group_id}
                                 member={member}
                                 date={date}
                                 mode={mode}
                    />)
                }
                return null;
            })}
        </div>
    );
}
