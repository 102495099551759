import {Router} from "@reach/router";
import Dashboard from "./dashboard/components/Dashboard";
import Welcome from "./welcome/components/Welcome";
import {NoPathWrapper} from "./welcome/components/NoPathWrapper";
import Main from "./main/components/Main";
import React from "react";

export default function IndexRouter () {
    return (
        <Router>
            <Dashboard path="/dashboard/*" />
            <Welcome path="/:loginType" />
            <Welcome path="/reset-password/:token/:email" />
            <NoPathWrapper path="/"/>
            <Main path="/*" />
            <NoPathWrapper default/>
        </Router>
    );
}