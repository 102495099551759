import React from "react";
import {VERSION, VERSION_NAME} from "../../../constants";
import "./footer.css";

export default function Footer(){

    return (
        <footer className="bg-gray-100 z-50 hidden xl:inline">
            <div className="col4">{VERSION + " " + VERSION_NAME}</div>
            <div className="col4"></div>
            <div className="col4 children-right">webmaster@lactam.be</div>
        </footer>
    )
}