import React from "react";
import {useIsCelActive, useIsPresent, useIsWeekendShift} from "./Hooks";
import {getExtraBorderStyles} from "./Cel";
import {useDispatch, useSelector} from "react-redux";
import {openMenu} from "../../../../actions/appActions";
import {selectShiftOffset} from "../../../../functions/getShift";

export default function Bonus({member, date, celData}){

    const state = useSelector(state => state);
    const shiftOffset = useSelector(selectShiftOffset)
    const shiftW = useIsWeekendShift(date, shiftOffset);
    const extraStyles = getExtraBorderStyles(date, state);
    const dispatch = useDispatch();
    const celActive = useIsCelActive(date, member.member_id);
    const weekendStyle = shiftW ? " whitespace" : "";
    const activeStyle = celActive ? " active" : "";
    const isPresent = useIsPresent(celData, member.member_id, date);

    let oor;
    let vuil;
    if(!shiftW) {
        if (celData !== null) {
            if(!isPresent) {
                return (<div>
                        <div className={"cel whitespace " + extraStyles}></div>
                        <div className={"cel whitespace " + extraStyles}></div>
                    </div>)
            }else{
                oor = parseInt(celData.oor) !== 0 ? celData.oor : null;
                vuil = parseInt(celData.vuil) !== 0 ? celData.vuil : null;
            }
        }
    }

    const handleClick = (e) => {
        const left = e.target.getBoundingClientRect().left;
        const top = e.target.getBoundingClientRect().top;
        dispatch(openMenu(member, date, [left, top]))
    };

    return (
        <div
             onClick={isPresent && !shiftW ? handleClick : null}
        >

            <div className={"cel " + extraStyles +  weekendStyle + activeStyle}>{vuil}</div>
            <div className={"cel " + extraStyles +  weekendStyle + activeStyle}>{oor}</div>
        </div>
    );
}