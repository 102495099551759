import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {navigate, Router} from "@reach/router";
import Navigation from "./navigation/Navigation";
import General from "./general/General";
import Members from "./members/Members";
import Groups from "./groups/Groups"
import Accounts from "./accounts/Accounts";
import NewAccount from "./accounts/New";
import NewMember from "./members/new/New";
import "./dashboard.css";
import Footer from "../../main/components/footer/footer";
import {TEAM_MANAGEMENT_LEVEL} from "../../constants";
import Schemes from "./schemes/schemes";

export default function Dashboard ()
{

    const user = useSelector(state => state.user);

    useEffect(() => {
        if (parseInt(user.level) < TEAM_MANAGEMENT_LEVEL || user.logged_in !== true) {
            navigate("/login");
        }
    }, [user]);

    useEffect(() => {
        // workaround: body is not rendered in tests and style does not matter
        const body = document.getElementById("body")
        if(body) body.className = "dashboard";
    }, []);

    return (
        <>
        <div className="dashboard-wrapper">
            <Navigation/>
            <section id="dashboard-content" data-testid="dashboard-content">
                <Router>
                    <General path="general"/>
                    <Members path="members"/>
                        <NewMember path="members/new" />
                    <Groups path="groups" />
                    <Schemes path="schemes"/>
                    <Accounts path="accounts" />
                        <NewAccount path="accounts/new" />
                </Router>
            </section>
        </div>
        <Footer/>
        </>
    )

}