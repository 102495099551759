
export function selectMembersListState(state){
    return state.dashboard.membersList;
}

export function selectCareersState(state){
    return state.dashboard.careers;
}

export function selectAccountsState(state){
    return state.dashboard.accounts;
}