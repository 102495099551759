import React from 'react';
import {useSelector} from "react-redux";
import {selectAppState} from "../../../reducers/selectors";
import {BONUS} from "../../../../constants";
import {Link} from "@reach/router";
import {isAllowed} from "../../../../functions/authorization/authorization";

export default function Member({member}) {

    const state = useSelector(state => state);
    const appState = useSelector(selectAppState);

    let empty = ""
    if (appState.mode === BONUS) {
        empty = <div className="cel user"></div>
    }

    if (isAllowed(state, "see-profile")) {
        return (
            <Link to={"/profile/" + member.member_id}>
                <div className="cel user overflow-hidden">{member.name}</div>
                {empty}
            </Link>
        );
    }else{
        return (
            <>
                <div className="cel user">{member.name}</div>
                {empty}
            </>
        );
    }

}
