import {RECEIVE_TEAM_RECORDS, SET_HOLIDAY} from "../actions/recordActions";
import {getMemberState} from "../../functions/state";
import {HOLIDAY_MAIN_BUTTONS, HOLIDAY_SEC_BUTTONS, IGNORE_SCHEME_TOKEN} from "../../constants";
import getStateDate from "../functions/getStateDate";
import {format} from "../../functions/date";
import {getCelData} from "../components/calendar/days/cel/Hooks";
import {isCustomSchemeActive} from "../../functions/scheme";
import {CHANGE_DATE} from "../actions/appActions";
import {selectAppState, selectMembersState, selectRecordsState, selectStatesStates} from "./selectors";
import {getGroups} from "../../functions/structure";
import {selectStructureState} from "../../redux/reducers/selectors";

export default function counterReducer(state={}, action, fullState) {

    if(state === {}) return state;

    if (action.type === RECEIVE_TEAM_RECORDS){
        return calculateCounters(action.records, fullState, action);
    }else if(action.type === CHANGE_DATE || action.type === SET_HOLIDAY) {
        return calculateCounters(selectRecordsState(fullState), fullState, action);
    }else{
        return state;
    }
}

function calculateCounters(records, state, action){

    let date = getStateDate(selectAppState(state));
    const structure = selectStructureState(state);
    const teamId = selectAppState(state).team_id;

    // dont use appState for date if action is CHANGE_DATE
    // the state is not yet updated if this code runs
    if(action.type === CHANGE_DATE){
        date.setFullYear(action.year);
        date.setMonth(action.month -1);
        date.setDate(1);
    }

    // create an array with all days in the month, formatted as strings
    const daysInMonth = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
    const days = new Array(daysInMonth).fill(0).map( (value, index) => {
        const d = date;
        d.setDate(index+1);
        return format(d);
    } );

    // loop all days
    return Object.fromEntries(days.map((date) => {

        let groups = {};
        let dayTotal = 0;
        let bediendes = 0;
        let bediendesTotal = 0;

        Object.entries(selectMembersState(state)).forEach( ([groupId, group]) => {
            Object.values(group).forEach( member => {

                const memberState = getMemberState(date, member.member_id, selectStatesStates(state));

                if(!getGroups(structure, teamId).includes(memberState.group_id)){
                    return;
                }

                if(memberState !== null) { // this can happen if records of old members are floating around in the database

                    const currentGroup = memberState.group_id;
                    if(!(currentGroup in groups)) {
                        groups[currentGroup] = {present: 0, total: 0}
                    }

                    // always increment total
                    groups[currentGroup].total += 1;
                    if(memberState.function === 1){
                        bediendesTotal += 1;
                    }

                    const record = getCelData(records, new Date(date), member.member_id);

                    // if present, increment present
                    if(
                        !HOLIDAY_MAIN_BUTTONS.concat(HOLIDAY_SEC_BUTTONS).includes(record.holiday)
                        && parseInt(memberState.enabled) === 1
                        && parseInt(memberState.occupation_counter) === 1
                        && parseInt(memberState.group_id) === parseInt(groupId)
                        && (!isCustomSchemeActive(state, member.member_id, new Date(date)) || record.holiday === IGNORE_SCHEME_TOKEN)
                    ) {
                        groups[currentGroup].present += 1;
                        dayTotal += 1;

                        if(parseInt(memberState.function) === 1){
                            bediendes += 1;
                        }
                    }
            }
            });
        });

        return [date,
            {...groups,
                total: dayTotal,
                bediendes: bediendes,
                bediendesTotal: bediendesTotal,
            }
        ];
    }));
}