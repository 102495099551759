import React, {useEffect} from "react";
import Row from "./list/Row";
import {useDispatch, useSelector} from "react-redux";
import {selectMembersListState} from "../../reducers/selectors";
import {changeDate} from "../../actions/membersListActions";
import {format} from "../../../functions/date";

export default function List ({members}) {

    const listState = useSelector(selectMembersListState);
    const dispatch = useDispatch();

    useEffect(() => {

        if(listState === undefined){
            dispatch(changeDate(format(new Date())));
        }
    });

    let groups = Object.entries(members).map(([groupId, group]) =>
         (
            <div className="group" key={groupId}>
                {
                    Object.values(group).map(member => {
                        return <Row member={member} key={member.member_id } />}
                    )
                }
            </div>
         )
    );

    return (
        <div className="member-list">
            <header className="member">
                <div className="name">naam</div>
                <div className="team"></div>
                <div className="group"></div>
                <div className="function">functie</div>
                <div className="education">opleiding</div>
                <div className="scheme">scheme</div>
                <div className="occupation">bezetting</div>
                <div className="holiday">verlof teller</div>
                <div className="holiday-days">verlof dagen</div>
            </header>
            {groups}
        </div>
    );
}