import React from 'react';
import { connect } from 'react-redux';
import { Link } from "@reach/router";
import getUrl from "../../../functions/getUrl";
import {selectAppState} from "../../../reducers/selectors";

class NavList extends React.Component {


    render() {

        let items = []
        Object.values(this.props.items).map((value => items.push(
                <Link
                    to={getUrl(this.props.appState, {[this.props.type]: value[1]})}
                    key={value}
                    className={(value[1] === this.props.current_state ? 'active': '') + " py-2"}
                    // onClick={()=>this.props.action(value[1])}
                    >
                    {value[0]}
                </Link>
        )))

        return (
            <>
                <div className="nav-list sub-section" id={this.props.type}>
                    <div className="title text-center my-1">{this.props.title}</div>
                        <div className="li-wrapper">
                            {items}
                        </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state, ownProps){

    const app = selectAppState(state);

    return {
        current_state: app[ownProps.type],
        appState: app,
    }
}

export default connect(mapStateToProps)(NavList)